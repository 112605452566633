import React, { useEffect, useState } from "react";
import { Link, NavLink } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faHome,
  faUser,
  faChartLine,
  faChartArea,
  faChartBar,
  faChartPie,
  faHistory,
  faTimesCircle,
  faCalendar,
  faGlobe,
  faBuilding,
  faUserAlt,
  faUserFriends,
  faBusinessTime,
  faGrinTongueWink,
  faBroadcastTower,
  faList,
  faPaperPlane,
  faArrowDown,
  faCircleArrowDown,
  faArrowCircleDown,
  faChevronCircleDown,
  faChevronDown,
  faDownload,
  faBars,
} from "@fortawesome/free-solid-svg-icons";

import { name, type } from "../common/AdminAuth";

const DashboardSideMenu = (props) => {
  const [pName, setPName] = useState("");
  // const [menuHide, setmenuHide] = useState("");
  const [userReport, setUserReport] = useState(false)
  const [companyManagement, setCompanyManagement] = useState(false)
  const [routeManagement, setRouteManagement] = useState(false)
  const [smseReport, setSmseReport] = useState(false)
  const [userManagement, setUserManagement] = useState(false)
  const [manager, setManager] = useState(false)
  const [billing, setBilling] = useState(false)
  const [download, setDownload] = useState(false)
  const [toggle,setToggle] = useState(true)
  // const [openMenus, setOpenMenus] = useState([]);

  useEffect(() => {
    collapseMenuUserReport();
    collapseMenuCompanyManagement();
    collapseMenuRouteManagement();
    collapseMenuSmscReport();
    collapseMenuUserManagement();
    collapseMenuManager();
    collapseMenuBilling();
    collapseMenuDownload();
  }, []);


  const checkActive = (match, location) => {
    if (!location) return false;
    const { pathname } = location;
    //console.log("location", location);
    setPName(pathname);
  };

  const menuHideShow = () => {
    let menubar = document.getElementById("mobile-menu");
    menubar.classList.remove("layout-menu-expanded");

  };



  const collapseMenuUserReport = async () => {
    if (window.location.pathname == "/admin/summary-report" || window.location.pathname == "/admin/details-report" || window.location.pathname == "/admin/summary-date-wise" || window.location.pathname == "/admin/global-search") {
      setUserReport(true)
      //console.log(window.location.pathname)
    }
    else {
      setUserReport(false)
    }
  }

  const collapseMenuCompanyManagement = async () => {
    if (window.location.pathname == "/admin/vendor" || window.location.pathname == "/admin/company" || window.location.pathname == `/admin/vendor/view-edit/${props.id}` || window.location.pathname == `/admin/vendor/route/${props.systemID}` || window.location.pathname == `/admin/add-company/view-edit/${props.id}` || window.location.pathname == `/admin/company/users/${props.id}` || window.location.pathname == "/admin/add-vendor" || window.location.pathname == "/admin/add-company") {
      setCompanyManagement(true)
    }
    else {
      setCompanyManagement(false)
    }
  }

  const collapseMenuRouteManagement = async () => {
    if (window.location.pathname == "/admin/all-route" || window.location.pathname == `/admin/vendor/price-list/${props.id}/${props.name}`) {
      setRouteManagement(true)
    }
    else {
      setRouteManagement(false)
    }
  }

  const collapseMenuSmscReport = async () => {
    if (window.location.pathname == "/admin/smsc-report" || window.location.pathname == "/admin/esme-report" || window.location.pathname == "/admin/add-smsc") {
      setSmseReport(true)
    }
    else {
      setSmseReport(false)
    }
  }

  const collapseMenuUserManagement = async () => {
    if (window.location.pathname == "/admin/user-list" || window.location.pathname == "/admin/user-assigned-route" || window.location.pathname == "/admin/balance-history" || window.location.pathname == `/admin/user/rate-route-list/${props.systemID}` || window.location.pathname == "/admin/add-user" || window.location.pathname == `/admin/add-user/edit/${props.username}`) {
      setUserManagement(true)
    }
    else {
      setUserManagement(false)
    }
  }

  const collapseMenuManager = async () => {
    if (window.location.pathname == "/admin/manager" || window.location.pathname == "/admin/add-manager") {
      setManager(true)
    }
    else {
      setManager(false)
    }
  }

  const collapseMenuBilling = async () => {
    if (window.location.pathname == "/admin/customer-billing" || window.location.pathname == "/admin/vendor-billing") {
      setBilling(true)
    }
    else {
      setBilling(false)
    }
  }

  const collapseMenuDownload = async () => {
    if (window.location.pathname == "/admin/request" || window.location.pathname == "/admin/list") {
      setDownload(true)
    }
    else {
      setDownload(false)
    }
  }

const toggleSideBar =() =>{
  setToggle(!toggle)
}


  return (
      <>
        <div id="mobile-menu">
      <aside
        id="layout-menu"
        className="layout-menu menu-vertical menu bg-menu-theme"
      >
        <div className="app-brand demo">
          <span className="app-brand-text demo menu-text fw-bolder ms-2">
            SMPP{" "}
          </span>
          <button className="menu-btn" onClick={menuHideShow}>
            {" "}
            <FontAwesomeIcon icon={faTimesCircle} />
          </button>
        </div>
        
        <ul className="menu-inner py-1">
          <li className={pName === "/admin" ? "menu-item active" : "menu-item"}>
            <NavLink className="menu-link" isActive={checkActive} to="/admin" >
              <FontAwesomeIcon
                className="menu-icon tf-icons bx bx-home-circle"
                icon={faHome}
              />
              <div style={{fontSize:"17px"}} data-i18n="Analytics"> Dashboard </div>
            </NavLink>
          </li>
        
          <li onClick={() => setUserReport(!userReport)} style={{ cursor: "pointer" , marginLeft:"7px", marginTop:"25px"}} className="menu text-uppercase">
          <FontAwesomeIcon className="menu-icon tf-icons bx bx-home-circle" icon={faChartArea}/> 
          <span className="menu-header-text">Reports</span><FontAwesomeIcon style={{ marginLeft: "138px" }} icon={faChevronDown} className="mr-2" />
          </li>
          {userReport && (<>
            <li
              className={
                pName.includes("/admin/summary-report")
                  ? "menu-item active"
                  : "menu-item"
              }
            >
              <Link to="/admin/summary-report" className="menu-link"  >
                <div  className="menu-header small text-uppercase" style={{margin:"0px 0px -10px 0px"}} data-i18n="Basic"> Summary Report </div>
              </Link>
            </li>
            <li
              className={
                pName.includes("/admin/details-report")
                  ? "menu-item active"
                  : "menu-item"
              }
            >
              <Link to="/admin/details-report" className="menu-link" >
                <div className="menu-header small text-uppercase" style={{margin:"-10px 0px -10px 0px"}} data-i18n="Basic"> Details Report </div>
              </Link>
            </li>

            <li
              className={
                pName.includes("/admin/summary-date-wise")
                  ? "menu-item active"
                  : "menu-item"
              }
            >
              <Link to="/admin/summary-date-wise" className="menu-link" >
                <div className="menu-header small text-uppercase" style={{margin:"-10px 0px -10px 0px"}}  data-i18n="Basic"> Date Wise Report </div>
              </Link>
            </li>
            {type === "Admin" && (
              <li
                className={
                  pName === "/admin/global-search"
                    ? "menu-item active"
                    : "menu-item"
                }
              >
                <Link to="/admin/global-search" className="menu-link">
                  <div className="menu-header small text-uppercase" style={{margin:"-10px 0px -10px 0px"}}  data-i18n="Basic"> Global Search </div>
                </Link>
              </li>
            )}
          </>)}


          <li onClick={() => setCompanyManagement(!companyManagement)} style={{ cursor: "pointer",marginLeft:"7px",marginTop:"25px" }} className="menu text-uppercase">
          <FontAwesomeIcon icon={faBuilding} className="mr-2" />
            <span className="menu-header-text">Company Management</span><FontAwesomeIcon style={{ marginLeft: "22px" }} icon={faChevronDown} className="mr-2" />
          </li>
          {companyManagement && (<>
            <li
              className={
                pName.includes("vendor") ? "menu-item active" : "menu-item"
              }
            >
              <Link to="/admin/vendor" className="menu-link">
                <div className="menu-header small text-uppercase" style={{margin:"0px 0px -10px -7px"}}  data-i18n="Support">Vendor Company </div>
              </Link>
            </li>

            <li
              className={
                pName.includes("company") ? "menu-item active" : "menu-item"
              }
            >
              <Link to="/admin/company" className="menu-link">
                <div className="menu-header small text-uppercase" style={{margin:"-10px 0px -10px -7px"}}  data-i18n="Support">Customer Company</div>
              </Link>
            </li>
          </>)}


          <li onClick={() => setRouteManagement(!routeManagement)} style={{ cursor: "pointer", marginLeft:"7px",marginTop:"25px" }} className="menu text-uppercase">
          <FontAwesomeIcon icon={faBroadcastTower} className="mr-2" />
            <span className="menu-header-text">Route Management</span><FontAwesomeIcon style={{ marginLeft: "42px" }} icon={faChevronDown} className="mr-2" />
          </li>
          {routeManagement && (<>
            <li
              className={
                pName.includes("all-route") ? "menu-item active" : "menu-item"
              }
            >
              <Link to="/admin/all-route" className="menu-link" >
                <div className="menu-header small text-uppercase" style={{margin:"-10px 0px -10px 0px"}}  data-i18n="Support">All Route </div>
              </Link>
            </li>
          </>)}

          {type === "Admin" ? (
            <>
              <li onClick={() => setSmseReport(!smseReport)} style={{ cursor: "pointer", marginLeft:"7px",marginTop:"25px" }} className="menu text-uppercase">
              <FontAwesomeIcon
                      className="menu-icon tf-icons bx bx-home-circle"
                      icon={faChartBar}
                    />
                <span className="menu-header-text">SMSC Report</span><FontAwesomeIcon style={{ marginLeft: "100px" }} icon={faChevronDown} className="mr-2" />
              </li>
              {smseReport && (<>
                <li
                  className={
                    pName === "/admin/smsc-report" || pName === "/admin/add-smsc"
                      ? "menu-item active"
                      : "menu-item"
                  }
                >
                  <Link to="/admin/smsc-report" className="menu-link">
                    <div className="menu-header small text-uppercase" style={{margin:"0px 0px -10px 0px"}}  data-i18n="Basic"> SMSC Report </div>
                  </Link>
                </li>
                <li
                  className={
                    pName === "/admin/esme-report"
                      ? "menu-item active"
                      : "menu-item"
                  }
                >
                  <Link to="/admin/esme-report" className="menu-link">
                    <div className="menu-header small text-uppercase" style={{margin:"-10px 0px -10px 0px"}}  data-i18n="Basic"> ESME Report </div>
                  </Link>
                </li>

                {/* <li
                className={
                  pName === "/admin/smsc-report-ec2" ||
                    pName === "/admin/add-smsc"
                    ? "menu-item active"
                    : "menu-item"
                }
              >
                <Link to="/admin/smsc-report-ec2" className="menu-link">
                  <FontAwesomeIcon
                    className="menu-icon tf-icons bx bx-home-circle"
                    icon={faChartBar}
                  />
                  <div data-i18n="Basic"> SMSC Report EC2 </div>
                </Link>
              </li> */}
                {/* <li
                className={
                  pName === "/admin/esme-report-ec2"
                    ? "menu-item active"
                    : "menu-item"
                }
              >
                <Link to="/admin/esme-report-ec2" className="menu-link">
                  <FontAwesomeIcon
                    className="menu-icon tf-icons bx bx-home-circle"
                    icon={faChartLine}
                  />
                  <div data-i18n="Basic"> ESME Report EC2 </div>
                </Link>
              </li> */}
              </>)}

            </>
          ) : null}

          <li onClick={() => setUserManagement(!userManagement)} style={{ cursor: "pointer", marginLeft:"7px" ,marginTop:"25px" }} className="menu  text-uppercase">
          <FontAwesomeIcon
                  className="menu-icon tf-icons bx bx-home-circle"
                  icon={faUserFriends}
                />
            <span className="menu-header-text">User Management</span><FontAwesomeIcon style={{ marginLeft: "53px" }} icon={faChevronDown} className="mr-2" />
          </li>
          {userManagement && (<>
            <li
              className={
                pName.includes("/admin/user-list") ||
                  pName.includes("/admin/add-user")
                  ? "menu-item active"
                  : "menu-item"
              }
            >
              <Link to="/admin/user-list" className="menu-link">
                <div className="menu-header small text-uppercase" style={{margin:"0px 0px -10px -16px"}}  data-i18n="Basic">All User list </div>
              </Link>
            </li>

            <li
              className={
                pName.includes("assigned") ? "menu-item active" : "menu-item"
              }
            >
              <Link to="/admin/user-assigned-route" className="menu-link">
                <div className="menu-header small text-uppercase" style={{margin:"-10px 0px -10px -16px"}}  data-i18n="Basic">User Assigned Route</div>
              </Link>
            </li>

            <li
              className={
                pName === "/admin/balance-history"
                  ? "menu-item active"
                  : "menu-item"
              }
            >
              <Link to="/admin/balance-history" className="menu-link" >
                <div className="menu-header small text-uppercase" style={{margin:"-10px 0px -10px -16px"}}  data-i18n="Basic"> Balance History </div>
              </Link>
            </li>
          </>)}


          {type === "Admin" ? (
            <>
              <li onClick={() => setManager(!manager)} style={{ cursor: "pointer", marginLeft:"7px", marginTop:"25px"}} className="menu text-uppercase">
              <FontAwesomeIcon icon={faUser} className="mr-2" />
                <span className="menu-header-text">Manager Add / List</span><FontAwesomeIcon style={{ marginLeft: "53px" }} icon={faChevronDown} className="mr-2" />
              </li>
              {manager && (<>
                <li
                  className={
                    pName === "/admin/manager" ? "menu-item active" : "menu-item"
                  }
                >
                  <Link to="/admin/manager" className="menu-link">
                    <div className="menu-header small text-uppercase" style={{margin:"-10px 0px -10px 0px"}} data-i18n="Support">Manager</div>
                  </Link>
                </li>
              </>)}


              <li onClick={() => setBilling(!billing)} style={{ cursor: "pointer", marginLeft: "7px",marginTop:"25px" }} className="menu text-uppercase">
              <FontAwesomeIcon icon={faUser} className="mr-2" />
                <span className="menu-header-text">Billing</span><FontAwesomeIcon style={{ marginLeft: "150px" }} icon={faChevronDown} className="mr-2" />
              </li>
              {billing && (<>
                <li
                  className={
                    pName.includes("/admin/customer-billing")
                      ? "menu-item active"
                      : "menu-item"
                  }
                >
                  <Link to="/admin/customer-billing" className="menu-link">
                    
                    <div className="menu-header small text-uppercase" style={{margin:"0px 0px -10px 0px"}}  data-i18n="Support">Customer Billing</div>
                  </Link>

                </li>

                <li
                  className={
                    pName.includes("/admin/vendor-billing")
                      ? "menu-item active"
                      : "menu-item"
                  }
                >
                  <Link to="/admin/vendor-billing" className="menu-link">
                    <div className="menu-header small text-uppercase" style={{margin:"-10px 0px -10px 0px"}} data-i18n="Support">Vendor Billing</div>
                  </Link>

                </li>
              </>)}

              <li onClick={() => setDownload(!download)} style={{ cursor: "pointer",marginLeft:"7px",marginTop:"25px" }} className="menu text-uppercase">
              <FontAwesomeIcon icon={faDownload} className="mr-2" />
                <span className="menu-header-text">Download</span><FontAwesomeIcon style={{ marginLeft: "120px" }} icon={faChevronDown} className="mr-2" />
              </li>
              {download && (<>
                <li
                  className={
                    pName.includes("/admin/request")
                      ? "menu-item active"
                      : "menu-item"
                  }
                >
                 <Link to="/admin/request" className="menu-link">
                    <div className="menu-header small text-uppercase" style={{margin:"0px 0px -10px 0px"}}  data-i18n="Support">Request</div>
                  </Link>

                </li>

                <li
                  className={
                    pName.includes("/admin/list")
                      ? "menu-item active"
                      : "menu-item"
                  }
                >
                  <Link to="/admin/list" className="menu-link">
                    <div className="menu-header small text-uppercase" style={{margin:"-10px 0px -10px 0px"}}  data-i18n="Support">List</div>
                  </Link>

                </li>
              </>)}
            </>
          ) : null}
        </ul>
      </aside>
    </div>
      </>
   
  
  
  );
};

export default DashboardSideMenu;
