import React, { useState, useEffect, useRef } from "react";
import AdminSideMenu from "../../navbar/AdminSideMenu";
import AdminTopMenu from "../../navbar/AdminTopMenu";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileExport, faFilePdf, faSearch } from "@fortawesome/free-solid-svg-icons";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { getCountryName, objectFlatten } from "../../Utils/Helper";
import { getMasterCountryList, vendorbililingAPi } from "../../../api/api";
import useSelectedDate from "../../../store/UseFilterDateStore";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import BillingPDF from "./BillingPDF";

const VendorBilling = () => {

  const selctedDateFrom = useSelectedDate((state) => state.selctedDateFrom);
  const selctedDateTo = useSelectedDate((state) => state.selctedDateTo);

  const [error, seterror] = useState({
    errarStatus: false,
    errorMessage: "",
    bgcolor: "",
  });

  var date = new Date();
  var todatdate =
    date.getFullYear() +
    "-" +
    (date.getMonth() + 1) +
    "-" +
    ("0" + date.getDate()).slice(-2);
  const reportTemplteref = useRef(null);
  const [companyList, setcompanyList] = useState([]);
  const [countryList, setCountryList] = useState([]);
  const [DateFrom, setDateFrom] = useState(selctedDateFrom);
  const [DateTo, setDateTo] = useState(selctedDateTo);
  const [showDateFrom, setshowDateFrom] = useState(null);
  const [showDateTo, setshowDateTo] = useState(null);
  const [selectedCompany, setselectedCompany] = useState("");
  const [selectedCompanyID, setSelectedCompanyID] = useState("");
  const [billingData, setBillingData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [method, setMethod] = useState('ven_summary_all');
  const [data, setData] = useState({})
  const [showPdf, setShowPdf] = useState(false)


  const detailsReport = () => {
    setBillingData([]);
    setIsLoading(true);
    vendorbililingAPi(method, DateFrom, DateTo, selectedCompanyID).then((result) => {
      if (result.data.success === true) {
        seterror({ errarStatus: false });
        setData(result.data.data)
        createNewObject(result.data.data)
        console.log(result.data.data)
        console.log('New OBJ', objectFlatten(result.data.data));
        setIsLoading(false);
      } else {
        seterror({
          errarStatus: true,
          errorMessage: result.data.message,
          bgcolor: "alert alert-danger",
        });
        setIsLoading(false);
      }
    })
  };

  const createNewObject = (data) => {
    let overallTotalCost = 0;
    let overallTotalSubmission = 0;

    const result = {};

    Object.keys(data).forEach((key) => {
      let mainKeyTotalCost = 0;
      let totalSubmission = 0;

      Object.keys(data[key]).forEach((x) => {
        Object.keys(data[key][x]).forEach((cost) => {
          let totalCost = parseFloat(data[key][x][cost].totalcost);
          mainKeyTotalCost += totalCost;

          // Correct calculation for total submissions
          let submissions = data[key][x][cost];
          let delivered = parseFloat(submissions.delivered) || 0;
          let failed = parseFloat(submissions.failed) || 0;
          let other = parseFloat(submissions.other) || 0;

          let submission = delivered + failed + other;
          totalSubmission += submission;
        });
      });

      // Accumulate main key total cost
      overallTotalCost += mainKeyTotalCost;
      overallTotalSubmission += totalSubmission;
    });


    console.log("Overall Total Cost:", overallTotalCost);
    console.log("Overall Total Submission", overallTotalSubmission);


    result.overallSubmission = overallTotalSubmission;
    result.overallTotalCost = overallTotalCost.toFixed(5);
    result.other = data

    console.log("result", result);
    if (Object.keys(result).length > 0) {
      // Set the result object to the billingData state
      setBillingData(result);
    }

  };

  useEffect(() => {
    getMasterCountryList().then((res) => {
      if (res.data.success === true) {
        setCountryList(res.data.data);
      } else {
        setCountryList([]);
      }
    });
  }, []);

  useEffect(() => {
    getCompanyList();
  }, []);


  const getCompanyList = async () => {
    let API = await fetch(`https://smppintl.datagenit.com/apismpp/v1/company_vendor.php?user_id=1&method=retrieve&token=b8860908f2cf45f721a40d23f2e291f9&type=Admin`)
    let data = await API.json()
    // console.log(data.data)
    setcompanyList(data.data)
  }

  const handleDateFrom = (date) => {
    let dated =
      date.getFullYear() +
      "-" +
      (date.getMonth() + 1) +
      "-" +
      ("0" + date.getDate()).slice(-2);
    setDateFrom(dated);
    setshowDateFrom(date);
  };

  const handleDateTo = (date) => {
    let dated =
      date.getFullYear() +
      "-" +
      (date.getMonth() + 1) +
      "-" +
      ("0" + date.getDate()).slice(-2);
    setDateTo(dated);
    setshowDateTo(date);
  };

  const userhandlar = (e) => {
    const select = e.target;
    const desc = select.selectedOptions[0].text;
    if (e.target.value === 'all') {
      setMethod('ven_summary_all');
    } else {
      setMethod('ven_summary_companywise');
    }
    setselectedCompany(desc)
    setSelectedCompanyID(e.target.value);
  };

  const generateNewPDF = async () => {
    const input = reportTemplteref.current;

    try {
      const canvas = await html2canvas(input)
      const imgData = canvas.toDataURL("img/png")
      const pdf = new jsPDF({
        orientation: "portrait",
        unit: "px",
        format: "a4"
      })

      const width = pdf.internal.pageSize.getWidth();
      const height = (canvas.height * width) / canvas.width

      pdf.addImage(imgData, "PNG", 0, 0, width, height);
      pdf.save("Vendorbilling.pdf");
    } catch (error) {
      console.log(error)
    }
  };

  return (
    <div className="layout-wrapper layout-content-navbar">
      <div className="layout-container">
        <AdminSideMenu />
        <div className="layout-page">
          <AdminTopMenu />
          <div className="content-wrapper">
            <div className="container-xxl container-p-y">
              <div className="row">
                <div className="col-xl-12">
                  <div className="card  mb-4">
                    <h5 className="card-header">Vendor Billing </h5>

                    <div className="card-body ">
                      <div className="row gy-3">
                        <div className="col-md-3">
                          <label>Company</label>
                          <div className="input-group">
                            <select
                              value={selectedCompanyID}
                              onChange={userhandlar}
                              name="selected-user"
                              className="form-select"
                            >
                              <option value="all">All Company  </option>
                              {companyList.map((items, i) => (
                                <option key={i} id="box1" value={items.id}>
                                  {items.short_name}
                                </option>
                              ))}
                            </select>
                          </div>
                        </div>

                        <div className="col-md-2 col-6">
                          <label>From Date</label>
                          <div className="">
                            <DatePicker
                              className="form-control btn-block"
                              dateFormat="MM/dd/yyyy"
                              selected={showDateFrom}
                              onChange={handleDateFrom}
                              placeholderText={todatdate}
                              maxDate={new Date()}
                            />
                          </div>
                        </div>
                        <div className="col-md-2 col-6">
                          <label> Date To</label>
                          <div className="input-group">
                            <DatePicker
                              startDate={showDateTo}
                              className="form-control btn-block"
                              dateFormat="MM/dd/yyyy"
                              selected={showDateTo}
                              onChange={handleDateTo}
                              placeholderText={todatdate}
                              minDate={new Date(showDateFrom)}
                            />
                          </div>
                        </div>

                        <div className="col-md-2">
                          <label>&nbsp;</label>
                          <div className="input-group">
                            {isLoading ? (
                              <button className="btn btn-block btn-primary me-1">
                                <FontAwesomeIcon icon={faSearch} /> Loading...
                              </button>
                            ) : (
                              <button
                                onClick={detailsReport}
                                className="btn btn-block btn-primary me-1"
                              >
                                <FontAwesomeIcon icon={faSearch} /> Search
                              </button>
                            )}
                          </div>
                        </div>
                        <div className="col-md-2">
                          <label>&nbsp;</label>
                          <div className="input-group">
                            <button
                              onClick={() => setShowPdf(true)}
                              className="btn btn-block btn-primary me-1"
                            >
                              <FontAwesomeIcon icon={faFilePdf} /> Show PDF
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-12 col-md-12 order-1">
                  <div className="">
                    {error.errarStatus ? (
                      <div className={error.bgcolor} role="alert">
                        <strong>Alert!</strong> {error.errorMessage}
                      </div>
                    ) : null}

                    {billingData && (
                      <div className="card pb-5">
                        {method == "ven_summary_companywise" && showPdf ? (
                          <>
                            <div><button className="float-right mr-3 btn btn-success" style={{ marginTop: "10px" }} onClick={generateNewPDF}><FontAwesomeIcon icon={faFileExport} /> Download PDF</button></div>
                            <div ref={reportTemplteref} >
                              <BillingPDF Vendormethod={method} VendorDateFrom={DateFrom} VendorDateTo={DateTo} VendorselectedCompanyID={selectedCompanyID} />
                            </div>
                          </>
                        ) : (
                          <>
                            <h5 className="card-header pb-0">
                              Total Cost of{" "}
                              <span className="text-primary ml-1 mr-1">
                                {selectedCompany ? selectedCompany : "All"}
                              </span>
                              Company is{" "}
                              <span className="text-info ml-1 badge badge-info text-white mr-2">
                                {billingData.overallTotalCost}
                              </span>
                              and total Submission is{" "}
                              <span className="text-info badge badge-info text-white">
                                {billingData.overallSubmission}
                              </span>
                            </h5>
                          </>
                        )}
                        <div className="p-4">
                          <div className="accordion">
                            {Object.keys(data)?.map((userName, i) => {
                              // Initialize total cost for the current user
                              let totalCostForUser = 0;
                              let totalSubmissionForUser = 0;

                              // Iterate over each country code for the current user
                              Object.keys(data[userName]).forEach((countryCode) => {
                                // Iterate over each status for the current country code
                                Object.keys(data[userName][countryCode]).forEach((status) => {
                                  // Access the total cost for the current status
                                  const totalCost = parseFloat(data[userName][countryCode][status].totalcost || 0);
                                  const submissions = data[userName][countryCode][status];

                                  const submission = parseFloat(submissions.delivered || 0) +
                                    parseFloat(submissions.failed || 0) +
                                    parseFloat(submissions.other || 0);

                                  // Add the total cost for the current status to the total cost for the user
                                  totalCostForUser += totalCost;
                                  totalSubmissionForUser += submission;
                                });
                              });

                              // Now totalCostForUser contains the total cost for the current user
                              console.log(`Total cost for ${userName}: ${totalCostForUser.toFixed(5)}`);
                              console.log(`Total submission for ${userName}: ${totalSubmissionForUser}`);

                              // Render the collapse with the total cost information
                              return (
                                <>
                                  <div key={i} className="card">
                                    <div
                                      role="button"
                                      data-toggle="collapse"
                                      data-target={`#faq${i}`}
                                      aria-expanded="true"
                                      aria-controls={`#faq${i}`}
                                      className="company-name"
                                      id={`faqhead${i}`}
                                    >
                                      <span className="btn btn-header-link">
                                        <span className="text-info badge badge-dark w-50 text-white ml-2 mr-2">
                                          {userName}
                                        </span>
                                        Total Cost :
                                        {Object.keys(data[userName]).forEach((cost) => {
                                          console.log(data[userName][cost])
                                        })}
                                        <span className="text-info badge badge-info text-white ml-2 mr-2">
                                          {totalCostForUser.toFixed(5)}
                                        </span>
                                        Total Submission :
                                        <span className="text-info badge badge-info text-white ml-2 mr-2">
                                          {totalSubmissionForUser}
                                        </span>

                                      </span>
                                    </div>
                                    <div
                                      id={`faq${i}`}
                                      className="collapse"
                                      aria-labelledby={`faqhead${i}`}
                                      data-parent="#faq"
                                    >
                                      <div className="card-body">
                                        {Object.keys(data[userName]).map((countryCode) => (
                                          <div key={countryCode}>
                                            <p className="fw-semibold d-block mb-1">
                                              {getCountryName(countryList, countryCode)}
                                            </p>
                                            <div className="d-flex gap-2">
                                              <small className="d-flex gap-5">
                                                {Object.keys(data[userName][countryCode]).map((status, i) => (
                                                  <>
                                                    <div key={i}>
                                                      <p className="text-capitalize">
                                                        {/* {status}:{" "} */}
                                                        {Object.entries(data[userName][countryCode][status]).map(
                                                          ([key, value], j) => {
                                                            // console.log(data[userName][countryCode][status].other)
                                                            return (
                                                              <>
                                                                <span style={{ marginLeft: "10px" }} key={j}>
                                                                  {key}: {value}&nbsp;
                                                                </span>
                                                              </>
                                                            )
                                                          }
                                                        )}
                                                      </p>
                                                    </div>
                                                    <p className="text-capitalize text-black">
                                                      Total Submission:{" "}
                                                      {parseInt(data[userName][countryCode][status].delivered || 0) +
                                                        parseInt(data[userName][countryCode][status].other || 0) +
                                                        parseInt(data[userName][countryCode][status].failed || 0)}
                                                    </p>
                                                  </>

                                                ))}

                                              </small>
                                            </div>
                                          </div>
                                        ))}
                                      </div>
                                    </div>
                                  </div>
                                </>
                              );
                            })}

                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default VendorBilling;
