import React, { useState, useEffect } from "react";
import AdminSideMenu from "../../navbar/AdminSideMenu";
import AdminTopMenu from "../../navbar/AdminTopMenu";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch, faRedoAlt } from "@fortawesome/free-solid-svg-icons";
import { URL } from "../../common/Url";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import DataTable from "react-data-table-component";
import { customTableStyles } from "../../common/customTableStyles";
import { userid, token, type } from "../../common/AdminAuth";
import { useParams } from "react-router-dom";
import { decodeMessage, getCountryName } from "../../Utils/Helper";
import { getMasterCountryList } from "../../../api/api";
import useSelectedDate from "../../../store/UseFilterDateStore";
const DetailsReport = () => {
  let { setuserid, setsernderid, dateform, dateto } = useParams();

  const [error, seterror] = useState({
    errarStatus: false,
    errorMessage: "",
    bgcolor: "",
  });

  var date = new Date();
  var todatdate =
    date.getFullYear() +
    "-" +
    (date.getMonth() + 1) +
    "-" +
    ("0" + date.getDate()).slice(-2);

  console.log("setuserid", setuserid);

  const setStoreDateFrom = useSelectedDate((state) => state.setStoreDateFrom);
  const setStoreDateTo = useSelectedDate((state) => state.setDateTo);
  const selctedDateFrom = useSelectedDate((state) => state.selctedDateFrom);
  const selctedDateTo = useSelectedDate((state) => state.selctedDateTo);

  const [DateFrom, setDateFrom] = useState(selctedDateFrom);
  const [DateTo, setDateTo] = useState(selctedDateTo);
  const [showDateFrom, setshowDateFrom] = useState(null);
  const [showDateTo, setshowDateTo] = useState(null);
  const [userList, setuserList] = useState([]);
  const [selectedUser, setselectedUser] = useState(setuserid || "");
  const [mobile, setmobile] = useState("");
  const [countrycode, setCountrycode] = useState(setsernderid || "");
  const [reportMobile, setReportMobile] = useState("");
  const [senderid, setsenderid] = useState("");
  const [detailsReportData, setdetailsReportData] = useState([]);
  const [deliveredReportData, setdeliveredReportData] = useState([]);
  const [companyList, setcompanyList] = useState([]);
  const [pending, setPending] = React.useState(true);

  const detailsReport = () => {
    setdetailsReportData([]);

    fetch(
      `${URL}report.php?user_id=${userid}&method=details&date_from=${DateFrom}&date_to=${DateTo}&search_user_id=${selectedUser}&senderid=${senderid}&country_code=${countrycode}&mobile=${mobile}&token=${token}&user_type=${type}`
    ).then((response) => {
      response.json().then((result) => {
        if (result.success === true) {
          seterror({ errarStatus: false });
          setdetailsReportData(result.message);
        } else {
          seterror({
            errarStatus: true,
            errorMessage: result.message,
            bgcolor: "alert alert-danger",
          });
        }
      });
    });
  };

  const deliveredReport = (message_id, dateTime, mobile) => {
    setdeliveredReportData([]);
    setReportMobile(mobile);
    fetch(
      `${URL}report.php?user_id=${userid}&method=delivery_status&message_id=${message_id}&token=${token}&user_type=${type}&submit_date=${dateTime}`
    ).then((response) => {
      response.json().then((result) => {
        if (result.success === true) {
          setdeliveredReportData(result.data);
        }
      });
    });
  };

  const getUserDetails = () => {
    fetch(
      `${URL}users.php?user_id=${userid}&method=retrieve&token=${token}&user_type=${type}`
    ).then((response) => {
      response.json().then((result) => {
        if (result.success === true) {
          setuserList(result.data);
          setPending(false);
        } else {
          seterror({
            errarStatus: true,
            errorMessage: result.message,
            bgcolor: "alert alert-danger",
          });
        }
      });
    });
  };

  useEffect(() => {
    getUserDetails();
    detailsReport();
    getCompanyList();
  }, []);

  const getCompanyList = () => {
    getMasterCountryList().then((res) => {
      if (res.data.success === true) {
        setcompanyList(res.data.data);
      } else {
        setcompanyList([]);
      }
    });
  };

  const handleDateFrom = (date) => {
    let dated =
      date.getFullYear() +
      "-" +
      (date.getMonth() + 1) +
      "-" +
      ("0" + date.getDate()).slice(-2);
    setDateFrom(dated);
    setStoreDateFrom(dated);
    setshowDateFrom(date);
  };

  const handleDateTo = (date) => {
    let dated =
      date.getFullYear() +
      "-" +
      (date.getMonth() + 1) +
      "-" +
      ("0" + date.getDate()).slice(-2);
    setDateTo(dated);
    setStoreDateTo(dated);
    setshowDateTo(date);
  };

  const userhandlar = (e) => {
    setselectedUser(e.target.value);
  };

  const columns = [
    {
      name: "Route",
      selector: (row) => row.smsc_id,
      sortable: true,
    },
    {
      name: "Message ID",
      selector: (row) => row.message_id,
      sortable: true,
      width: "350px",
    },
    {
      name: "User Name",
      selector: (row) => row.system_id,
      sortable: true,
    },
    {
      name: "SMS Cost",
      selector: (row) => row.smscost,
      sortable: true,
    },
    {
      name: "Mobile No.",
      selector: (row) => row.msisdn,
      width: "150px",
      sortable: true,
    },
    {
      name: "Country Code",
      selector: (row) => getCountryName(companyList, row.country_code),
      width: "150px",
      sortable: true,
    },
    {
      name: "Check Report",
      cell: (e) => (
        <span
          onClick={() => deliveredReport(e.message_id, e.sent_date, e.msisdn)}
          data-toggle="modal"
          role="button"
          data-target="#exampleModal"
          className="badge bg-info mr-2"
        >
          {" "}
          <FontAwesomeIcon icon={faRedoAlt} /> Report{" "}
        </span>
      ),
      sortable: true,
    },
    {
      name: "HEADER",
      selector: (row) => row.sender,
      sortable: true,
      width: "150px",
    },
    {
      name: "SUBMIT TIME",
      selector: (row) => row.sent_date,
      sortable: true,
      wrap: true,
    },
    {
      name: "Count",
      selector: (row) => (row.sms_split === 0 ? "1" : row.sms_split),
      sortable: true,
      width: "100px",
    },
    {
      name: "MESSAGE",
      selector: (row) => decodeMessage(row.message_text),
      wrap: true,
      width: "500px",
      fontSize: "12px",
    },
  ];

  return (
    <div className="layout-wrapper layout-content-navbar">
      <div className="layout-container">
        <AdminSideMenu />
        <div className="layout-page">
          <AdminTopMenu />
          <div className="content-wrapper">
            <div className="container-xxl container-p-y">
              <div className="row">
                <div className="col-xl-12">
                  <div className="card  mb-4">
                    <h5 className="card-header">Filter Options</h5>

                    <div className="card-body ">
                      <div className="row gy-3">
                        <div className="col-md-6 col-6">
                          <label>Users</label>
                          <div className="input-group">
                            <select
                              value={selectedUser}
                              onChange={userhandlar}
                              name="selected-user"
                              className="form-select"
                            >
                              <option value="">Select User </option>
                              {userList.map((items, i) => (
                                <option
                                  key={i}
                                  value={items.system_id.toLowerCase()}
                                >
                                  {items.system_id}
                                </option>
                              ))}
                            </select>
                          </div>
                        </div>

                        <div className="col-md-3 col-6">
                          <label>From Date</label>
                          <div className="input-group">
                            <button
                              className="btn btn-outline-primary d-none d-sm-block"
                              type="button"
                              id="button-addon2"
                            >
                              From
                            </button>
                            <DatePicker
                              className="form-control btn-block"
                              dateFormat="MM/dd/yyyy"
                              selected={showDateFrom}
                              onChange={handleDateFrom}
                              placeholderText={dateform}
                            />
                          </div>
                        </div>
                        <div className="col-md-3 col-6">
                          <label>To Date</label>
                          <div className="input-group">
                            <button
                              className="btn btn-outline-primary d-none d-sm-block"
                              type="button"
                              id="button-addon2"
                            >
                              To
                            </button>
                            <DatePicker
                              className="form-control btn-block"
                              dateFormat="MM/dd/yyyy"
                              selected={showDateTo}
                              onChange={handleDateTo}
                              placeholderText={dateto}
                            />
                          </div>
                        </div>

                        <div className="col-md-4 col-6">
                          <label>Mobile no. / Message id</label>
                          <div className="input-group">
                            <input
                              type="text"
                              onChange={(e) => setmobile(e.target.value)}
                              className="form-control"
                              placeholder="Enter mobile Number"
                            />
                          </div>
                        </div>

                        <div className="col-md-4 col-6">
                          <label>Country Code</label>
                          <div className="input-group">
                            <input
                              type="text"
                              value={countrycode}
                              onChange={(e) => setCountrycode(e.target.value)}
                              className="form-control"
                              placeholder="Enter Country Code"
                            />
                          </div>
                        </div>

                        <div className="col-md-4 col-6">
                          <label>Sender ID</label>
                          <div className="input-group">
                            <input
                              type="text"
                              value={senderid}
                              onChange={(e) => setsenderid(e.target.value)}
                              className="form-control"
                              placeholder="Enter Sender ID"
                            />
                          </div>
                        </div>

                        <div className="col-md-4">
                          <label>&nbsp;</label>
                          <div className="input-group">
                            <button
                              onClick={detailsReport}
                              className="btn btn-block btn-primary me-1"
                            >
                              <FontAwesomeIcon icon={faSearch} /> Search
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-lg-12 col-md-4 order-1">
                  <div className="">
                    {error.errarStatus ? (
                      <div className={error.bgcolor} role="alert">
                        <strong>Alert!</strong> {error.errorMessage}
                      </div>
                    ) : null}

                    <div className="card pb-5">
                      <h5 className="card-header">Detailed Report</h5>

                      <DataTable
                        pagination
                        handleSort
                        columns={columns}
                        data={detailsReportData}
                        paginationRowsPerPageOptions={[10, 25, 50, 100]}
                        progressPending={pending}
                        customStyles={customTableStyles}
                        noDataComponent="Please Select User"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        className="modal fade"
        id="exampleModal"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div
          className="modal-dialog modal-lg modal-dialog-centered"
          role="document"
        >
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">
                Delivery Report
              </h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <div className="table-responsive">
                <table className="table table-striped">
                  <thead>
                    <tr style={{ fontSize: "13px" }}>
                      <th scope="col">Mobile no.</th>
                      <th scope="col">Status</th>
                      <th scope="col">Country Code</th>
                      <th scope="col">Operator ID</th>
                      <th scope="col">Submit Time</th>
                      <th scope="col">Delivery Time</th>
                      <th scope="col">DLR Reason</th>
                      <th scope="col">DLR Code</th>
                    </tr>
                  </thead>
                  <tbody>
                    {deliveredReportData.length > 0 ? (
                      <>
                        {deliveredReportData.map((items, i) => (
                          <>
                            <tr key={i} style={{ fontSize: "13px" }}>
                              <td>{reportMobile}</td>
                              <td>
                                {" "}
                                <strong>
                                  {" "}
                                  {items.delivery_status === "success" ? (
                                    <span className="text-success">
                                      {items.delivery_status}
                                    </span>
                                  ) : (
                                    <span className="text-danger">
                                      {items.delivery_status}
                                    </span>
                                  )}
                                </strong>
                              </td>
                              <td> {items.country_code}</td>
                              <td> {items.operatorid}</td>
                              <td> {items.submit_time}</td>
                              <td>{items.delivery_time}</td>
                              <td>{items.dlr_reason}</td>
                              <td>{items.dlr_code}</td>
                            </tr>
                          </>
                        ))}
                      </>
                    ) : (
                      <tr style={{ fontSize: "13px", textAlign: "center" }}>
                        <td colSpan={8}>
                          {" "}
                          <strong>
                            {" "}
                            <span className="text-info">DLR Awaited....</span>
                          </strong>
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DetailsReport;
