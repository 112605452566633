import { create } from "zustand";

const useSelectedDate = create((set, get) => {
  var date = new Date();
  var todatdate =
    date.getFullYear() +
    "-" +
    (date.getMonth() + 1) +
    "-" +
    ("0" + date.getDate()).slice(-2);

  return {
    selctedDateFrom: todatdate,
    selctedDateTo: todatdate,
    setStoreDateFrom: (data) => {
      set({
        selctedDateFrom: data,
      });
    },
    setDateTo: (data) => {
      set({
        selctedDateTo: data,
      });
    },
  };
});

export default useSelectedDate;
