import React, { useEffect, useState } from "react";
import {
  addrouteAndRateAPI,
  getAllCountryList,
  getRouteList,
  getRouteWiseCountryList,
  retrieveRouteAndRateList,
} from "../../api/api";
import { token, type, userid } from "../../component/common/AdminAuth";
import { numberOnly } from "../Utils/Helper";
import Select from 'react-select'

function AddRouteRatePopup(props) {
  const [countryList, setCountryList] = useState([]);
  const [route, setRoute] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState({
    errorStatus: false,
    errorMessage: "",
    bgcolor: "",
  });

  const [data, setData] = useState({
    price: "",
    country_code: "",
    route: "",
    status: "1",
    method: "add",
    buy_price: "",
    system_id: props.selectedSystemID,
    user_id: userid,
    token: token,
    type: type,
  });
  const [selectedOption, setSelectedOption] = useState(null);

  const handleChange = (selectedOption) => {
    setSelectedOption(selectedOption);
    if (selectedOption) {
      setData({...data , route : selectedOption.value})
      getCountryList(selectedOption.id);
    } else {
      // Handle when no option is selected
    }
  };
  
  useEffect(() => {
    getRouteList().then((res) => {
      setRoute(res.data.data);
    });
    setData((prevData) => ({
      ...prevData,
      system_id: props.selectedSystemID,
    }));
  }, [props.selectedSystemID]);

  useEffect(() => {
    if (props.type === "edit" && props.id) {
      retrieveRouteAndRateList(props.id, props.selectedSystemID).then((res) => {
        console.log("res Update Data ", res.data);
        let newData = { ...data };
        newData.country_code = numberOnly(res.data.data[0].regex);
        newData.price = res.data.data[0].cost;
        newData.route = res.data.data[0].smsc_id;
        newData.method = "update";
        newData.id = props.id;
        newData.system_id = props.selectedSystemID;
        console.log(numberOnly(res.data.data[0].regex));
        routeWiseCountryList(res.data.data[0].smsc_id);
        setData(newData);
      });
    }
  }, [props.id]);

  const addrouteAndRate = () => {
    setIsLoading(true);
    addrouteAndRateAPI(data).then((res) => {
      if (res.data.success === true) {
        setError({
          errorStatus: true,
          errorMessage: res.data.message,
          bgcolor: "alert alert-success",
        });
        setTimeout(() => {
          window.location.href = `/admin/user/rate-route-list/${props.selectedSystemID}`;
        }, 1000);
      } else {
        setError({
          errorStatus: true,
          errorMessage: res.data.message,
          bgcolor: "alert alert-danger",
        });
        setIsLoading(false);
      }
    });
  };

  const update = () => {
    setIsLoading(true);
    addrouteAndRateAPI(data).then((res) => {
      if (res.data.success === true) {
        setError({
          errorStatus: true,
          errorMessage: res.data.message,
          bgcolor: "alert alert-success",
        });
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      } else {
        setError({
          errorStatus: true,
          errorMessage: res.data.message,
          bgcolor: "alert alert-danger",
        });
        setIsLoading(false);
      }
    });
  };

  // useEffect(()=>{
  //   getCountryList();
  //   routeWiseCountryList();
  // },[])

  const getCountryList = (id) => {
    getAllCountryList(id).then((res) => {
      setCountryList(res.data.data);
    });
  };

  const routeWiseCountryList = (id) => {
    getRouteWiseCountryList(id).then((res) => {
      setCountryList(res.data.data);
    });
  };


  const countryHandler = (e) => {
    const selectedIndex = e.target.selectedIndex;
    const optionElement = e.target.childNodes[selectedIndex];
    const buyingPrice = optionElement.getAttribute("data-id");
    const sellingPrice = optionElement.getAttribute("selling");
    setData((prevData) => ({
      ...prevData,
      country_code: e.target.value,
      buy_price: buyingPrice,
      price: sellingPrice,
    }));
  };

  return (
    <div
      className="modal fade"
      id="exampleModalCenter"
      tabIndex="-1"
      role="dialog"
      aria-labelledby="exampleModalCenterTitle"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-centered" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="exampleModalLongTitle">
              {props.type === "edit" ? "Update" : "Add"} Rate / Route for{" "}
              <span className="text-success">{props.selectedSystemID} </span>
            </h5>
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body row">
            <div className="col-md-12">
              {error.errorStatus && (
                <div className={error.bgcolor} role="alert">
                  <strong>Alert!</strong> {error.errorMessage}
                  <button
                    type="button"
                    className="close"
                    data-dismiss="alert"
                    aria-label="Close"
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
              )}
            </div>

            <div className="form-group col-md-6">
              {route.length > 0 && (
                // <select
                //   value={data.route}
                //   onChange={(e) => {
                //     var index = e.target.selectedIndex;
                //     var optionElement = e.target.childNodes[index];
                //     var id = optionElement.getAttribute("data-id");
                //     setData({
                //       ...data,
                //       route: e.target.value,
                //     });
                //     getCountryList(id);
                //   }}
                //   className="form-select"
                // >
                //   <option>Select Route</option>
                //   {route.map((items, i) => (
                //     <option key={i} data-id={items.id} value={items.title}>
                //       {items.title}
                //     </option>
                //   ))}
                // </select>
                <Select
                  value={selectedOption} // You'll need to manage selectedOption in your state
                  onChange={handleChange}
                  options={route.map(item => ({ value: item.title, label: item.title, id: item.id }))}
                  getOptionLabel={(option) => option.label}
                  getOptionValue={(option) => option.value}
                  placeholder="Select Route"
                />
              )}
            </div>

            <div className="form-group col-md-6">
              <select
                value={data.country_code}
                onChange={countryHandler}
                className="form-select"
              >
                <option>Select Country</option>
                {countryList &&
                  countryList.map((item, index) => (
                    <option
                      key={index}
                      data-id={item.buy_price}
                      selling={item.selling_price}
                      value={item.country_code}
                    >
                      {item.country_name} ({item.country_code})
                    </option>
                  ))}
              </select>
            </div>

            {data.buy_price && (
              <div className="form-group d-flex align-items-center gap-5 col-md-12">
                <label className=" m-0">Buy Price</label>
                <input
                  placeholder="Enter price"
                  className="form-control"
                  disabled
                  value={data.buy_price}
                  type="number"
                />
              </div>
            )}

            <div className="form-group d-flex align-items-center col-md-12">
              <label className="w-25 m-0">Selling Price</label>
              <input
                placeholder="Enter price"
                className="form-control"
                defaultValue={data.price}
                type="number"
                onChange={(e) =>
                  setData((prevData) => ({
                    ...prevData,
                    price: e.target.value,
                  }))
                }
              />
            </div>
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-secondary"
              data-dismiss="modal"
            >
              Close
            </button>
            {isLoading ? (
              <button type="button" className="btn btn-primary">
                Loading.....
              </button>
            ) : (
              <>
                {props.type === "edit" ? (
                  <button onClick={update} type="button" className="btn btn-primary">
                    Update
                  </button>
                ) : (
                  <button onClick={addrouteAndRate} type="button" className="btn btn-primary">
                    Add
                  </button>
                )}
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default AddRouteRatePopup;
