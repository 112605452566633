import React, { useEffect, useState } from 'react';
import { token, type, userid } from '../../../common/AdminAuth';
import Select from 'react-select'

const AddPrice = (props) => {
    const [data, setData] = useState({
        country: {
            country_name: '',
            country_code: ''
        },
        buy_price: "",
        rate_type: "isim"
    });

    const [error, setError] = useState({
        errorStatus: false,
        errorMessage: "",
        bgcolor: "",
    });

    const [countryList, setCountryList] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [selectCountry,setselectCountry] = useState('')
    

    let company_name = props.company_name
    console.log(company_name)

    useEffect(() => {
        getCountry();
    }, []);

    const getCountry = async () => {
        let param = {
            user_id: userid,
            method: "retrieve",
            token: token,
            user_type: type
        };

        try {
            let api = await fetch("https://smppintl.datagenit.com/apismpp/v1/country_master.php", {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(param),
            });

            if (!api.ok) {
                throw new Error('Failed to fetch countries');
            }

            let data = await api.json();
            setCountryList(data.data);
        } catch (error) {
            console.error('Error fetching countries:', error);
            // Handle error state or display error message
        }
    };

    const handleCountry = (event) => {
        const [countryName, countryCode] = event.target.value.split(',');
        setData({
            ...data,
            country: {
                country_name: countryName,
                country_code: countryCode
            }
        });
    };

    const onChange = (e) => {
        setData({ ...data, [e.target.name]: e.target.value });
    };

    const isValid = () => {
        if (!selectCountry.label || selectCountry.label  === '') {
            setError({
                errorStatus: true,
                errorMessage: "Please Select Country",
                bgcolor: "alert alert-danger",
            });
            return false;
        }
        if (!data.buy_price || data.buy_price === '') {
            setError({
                errorStatus: true,
                errorMessage: "Please Enter Price",
                bgcolor: "alert alert-danger",
            });
            return false;
        }
        // Regular expression to allow both integers and floating point numbers
        if (!/^\d+(\.\d+)?$/.test(data.buy_price)) {
            setError({
                errorStatus: true,
                errorMessage: "Please Enter valid Price (numeric characters only)",
                bgcolor: "alert alert-danger",
            });
            return false;
        }
        return true;
    };
    

    const addRate = async (e) => {
        e.preventDefault();
        if (isValid()) {
            let param = {
                route_id: props.addPrice.id,
                route_name: props.addPrice.verdor_name,
                vendor_name: props.company_name,
                country_name: selectCountry.label,
                country_code: selectCountry.value,
                rate_type: data.rate_type,
                buy_price: data.buy_price,
                method: "add_price",
                token: token,
                type: type,
                user_id: userid
            }
            let api = await fetch("https://smppintl.datagenit.com/apismpp/v1/route_pricelist.php", {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(param),
            })
            let result = api.json()
            if(result){
                console.log("data Addd sucessfully")
            }
                setTimeout(() => {
                    window.location.reload(true)
                  }, 1000); 
            
        }
    };

    const accountTypeHandler = (selectedOption) => {
        setselectCountry(selectedOption); 
    };

    console.log(data)
    console.log(props.addPrice.id)

    console.log(selectCountry)

    return (
        <div
            className="modal fade"
            id="addPrice"
            tabIndex="-1"
            role="dialog"
            aria-labelledby="exampleModalCenterTitle"
            aria-hidden="true"
        >
            <div className="modal-dialog modal-dialog-centered" role="document">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title" id="exampleModalLongTitle">
                            {props.type === "edit" ? "Update" : "Add"} Rate For {" "}
                            <span className="text-success">{props.addPrice.verdor_name}</span>
                        </h5>
                        <button
                            type="button"
                            className="close"
                            data-dismiss="modal"
                            aria-label="Close"
                        >
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body row">
                        <div className="col-md-12">
                            {error.errorStatus && (
                                <div className={error.bgcolor} role="alert">
                                    <strong>Alert!</strong> {error.errorMessage}
                                    <button
                                        type="button"
                                        className="close"
                                        data-dismiss="alert"
                                        aria-label="Close"
                                    >
                                        <span aria-hidden="true">&times;</span>
                                    </button>
                                </div>
                            )}
                        </div>
                        <div className="form-group col-md-6">
                            <label>Select Country</label>
                            {/* <select
                                value={`${data.country.country_name},${data.country.country_code}`}
                                onChange={handleCountry}
                                className="form-select"
                                name='country'
                            >
                                <option value="">Select Country</option>
                                {countryList.map((item, i) => (
                                    <option
                                        key={i}
                                        value={`${item.country_name},${item.country_code}`}
                                    >
                                        {item.country_name}
                                    </option>
                                ))}
                            </select>                            */}
                            <Select
                                options={countryList.map((item, i) => (
                                    {label : item.country_name,value : item.country_code}
                                ))}
                                name="country"
                                value={selectCountry} // This should be an object containing the selected user's label and value
                                onChange={accountTypeHandler}
                                placeholder="Select Country"
                                isSearchable
                            />
                        </div>
                        {/* <div className="form-group col-md-6">
                            <label htmlFor="rate_type">Rate Type</label>
                            <select
                                value={data.rate_type}
                                onChange={onChange}
                                className="form-select"
                                name='rate_type'
                                id='rate_type' 
                            >
                                <option value="isim">isim</option>
                                <option value="iwh">iwh</option>
                                <option value="idir">idir</option>
                                <option value="iws">iws</option>
                                <option value="ihd">ihd</option>
                       
                            </select>
                        </div> */}
                        <div className="form-group col-md-6">
                            <label>Buying Price (Euro)</label>
                            <input type='number' name='buy_price' className="form-control  input-num-rm" value={data.buy_price} onChange={onChange} />
                        </div>
                    </div>
                    <div className="modal-footer">
                        <button
                            type="button"
                            className="btn btn-secondary"
                            data-dismiss="modal"
                        >
                            Close
                        </button>
                        {isLoading ? (
                            <button type="button" className="btn btn-primary">
                                Loading.....
                            </button>
                        ) : (
                            <>
                                {props.type === "edit" ? (
                                    <button
                                        onClick={"update"}
                                        type="button"
                                        className="btn btn-primary"
                                    >
                                        Update
                                    </button>
                                ) : (
                                    <button
                                        onClick={addRate}
                                        type="button"
                                        className="btn btn-primary"
                                    >
                                        Add
                                    </button>
                                )}
                            </>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AddPrice;
