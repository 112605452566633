import React, { useEffect, useState } from "react";
import { addRoute } from "../../../../api/api";
import { token, type, userid } from "../../../../component/common/AdminAuth";

function PopupVendor(props) {
  console.log("props", props);
  const [isLoading, setIsLoading] = useState(false);
  const [is_trx, setIs_trx] = useState(true);

  const [error, seterror] = useState({
    errarStatus: false,
    errorMessage: "",
    bgcolor: "",
  });

  const [data, setData] = useState({
    title: "",
    vendor_username: "",
    manager_id: props.vendorData.manager_id,
    parent_id: props.vendorData.parent_id,
    password: "",
    ip_address: "",
    is_trx: 0,
    trx_port: "",
    tx_port: "",
    rx_port: "",
    trx: "",
    tx: "",
    rx: "",
    status: "0",
    method: "create",
    display_type: "0",
    user_id: userid,
    token: token,
    type: type,
  });

  useEffect(() => {
    setData({
      ...data,
      parent_id: props.vendorData.parent_id,
      manager_id: props.vendorData.manager_id,
    });
    if (props.type === "edit") {
      addRoute("retrieveid", null, props.vendorData.manager_id).then((res) => {
        console.log("Receive data ", res.data);
        if (res.data.success) {
          let Newdata = { ...data };
          Newdata.ip_address = res.data.data[0].ip_address;
          Newdata.title = res.data.data[0].title;
          Newdata.password = res.data.data[0].password;
          Newdata.vendor_username = res.data.data[0].vendor_username;
          Newdata.tx_port = res.data.data[0].tx_port;
          Newdata.tx = res.data.data[0].tx;
          Newdata.trx_port = res.data.data[0].trx_port;
          Newdata.rx = res.data.data[0].rx;
          Newdata.rx_port = res.data.data[0].rx_port;
          Newdata.rx_port = res.data.data[0].rx_port;
          Newdata.manager_id = props.vendorData.manager_id;
          setIs_trx(res.data.data[0].is_trx === 1 ? true : false);
          setData(Newdata);
        }
      });
    }
  }, [props.vendorData]);

  const addrouteAndRate = () => {
    setIsLoading(true);
    addRoute("create", data).then((res) => {
      if (res.data.success === true) {
        seterror({
          errarStatus: true,
          errorMessage: res.data.message,
          bgcolor: "alert alert-success",
        });
        window.location.reload();
      } else {
        seterror({
          errarStatus: true,
          errorMessage: res.data.message,
          bgcolor: "alert alert-danger",
        });
        setIsLoading(false);
      }
    });
  };

  const update = () => {
    setIsLoading(true);
    let updatedata = { ...data };
    updatedata.token = token;
    updatedata.type = type;
    updatedata.method = "update";
    updatedata.user_id = userid;
    updatedata.id = props.vendorData.manager_id;

    console.log("updatedata", updatedata);

    addRoute("update", updatedata).then((res) => {
      if (res.data.success === true) {
        seterror({
          errarStatus: true,
          errorMessage: res.data.message,
          bgcolor: "alert alert-success",
        });
        setTimeout(() => {
          // window.location.reload();
        }, 1000);
      } else {
        seterror({
          errarStatus: true,
          errorMessage: res.data.message,
          bgcolor: "alert alert-danger",
        });
        setIsLoading(false);
      }
    });
  };

  return (
    <div
      className="modal fade"
      id="vendor"
      tabIndex="-1"
      role="dialog"
      aria-labelledby="exampleModalCenterTitle"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-centered" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="exampleModalLongTitle">
              {props.type === "edit" ? "Update" : "Add"} route for
              <span className="text-success">
                {" "}
                {props.vendorData.verdor_name}
              </span>
            </h5>
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body row">
            <div className="col-md-12">
              {error.errarStatus ? (
                <div className={error.bgcolor} role="alert">
                  <strong>Alert!</strong> {error.errorMessage}
                  <button
                    type="button"
                    className="close"
                    data-dismiss="alert"
                    aria-label="Close"
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
              ) : null}
            </div>

            <div className="form-group col-md-12">
              <input
                placeholder="Enter Title"
                className="form-control"
                defaultValue={data.title}
                type="text"
                onChange={(e) => {
                  setData({
                    ...data,
                    title: e.target.value,
                  });
                }}
              />
            </div>
            <div className="form-group col-md-6">
              <input
                placeholder="Enter vendor username"
                className="form-control"
                type="text"
                defaultValue={data.vendor_username}
                onChange={(e) => {
                  setData({
                    ...data,
                    vendor_username: e.target.value,
                  });
                }}
              />
            </div>
            <div className="form-group col-md-6">
              <input
                placeholder="Enter password"
                className="form-control"
                defaultValue={data.password}
                type="text"
                onChange={(e) => {
                  setData({
                    ...data,
                    password: e.target.value,
                  });
                }}
              />
            </div>
            <div className="form-group col-md-12">
              <input
                placeholder="Enter ip address"
                className="form-control"
                defaultValue={data.ip_address}
                type="text"
                onChange={(e) => {
                  setData({
                    ...data,
                    ip_address: e.target.value,
                  });
                }}
              />
            </div>
            <div className="form-group col-md-12">
              <div className="form-check">
                <input
                  defaultValue={data.is_trx}
                  onChange={() => setIs_trx(!is_trx)}
                  className="form-check-input"
                  type="checkbox"
                  value=""
                  checked={is_trx}
                  id="flexCheckChecked"
                />
                <label className="form-check-label" for="flexCheckChecked">
                  TRX
                </label>
              </div>
            </div>

            {is_trx ? (
              <>
                <div className="form-group col-md-6">
                  <input
                    placeholder="Enter TRX Port"
                    className="form-control"
                    defaultValue={data.trx_port}
                    type="text"
                    onChange={(e) => {
                      setData({
                        ...data,
                        trx_port: e.target.value,
                      });
                    }}
                  />
                </div>
                <div className="form-group col-md-6">
                  <select
                    onChange={(e) => {
                      setData({
                        ...data,
                        trx: e.target.value,
                      });
                    }}
                    value={data.trx}
                    className="form-select"
                  >
                    <option value={0}> Select TRX Bind </option>
                    <option value={1}> 1</option>
                    <option value={2}> 2</option>
                    <option value={3}> 3</option>
                    <option value={4}> 4</option>
                    <option value={5}> 5</option>
                    <option value={6}> 6</option>
                    <option value={7}> 7</option>
                    <option value={8}> 8</option>
                    <option value={9}> 9</option>
                    <option value={10}> 10</option>
                  </select>
                </div>
              </>
            ) : (
              <>
                <div className="form-group col-md-6">
                  <input
                    placeholder="Enter RX Port"
                    className="form-control"
                    defaultValue={data.rx_port}
                    type="text"
                    onChange={(e) => {
                      setData({
                        ...data,
                        rx_port: e.target.value,
                      });
                    }}
                  />
                </div>
                <div className="form-group col-md-6">
                  <select
                    onChange={(e) => {
                      setData({
                        ...data,
                        rx: e.target.value,
                      });
                    }}
                    value={data.rx}
                    className="form-select"
                  >
                    <option value={0}> Select RX Bind </option>
                    <option value={1}> 1</option>
                    <option value={2}> 2</option>
                    <option value={3}> 3</option>
                    <option value={4}> 4</option>
                    <option value={5}> 5</option>
                    <option value={6}> 6</option>
                    <option value={7}> 7</option>
                    <option value={8}> 8</option>
                    <option value={9}> 9</option>
                    <option value={10}> 10</option>
                  </select>
                </div>

                <div className="form-group col-md-6">
                  <input
                    placeholder="Enter Tx Port"
                    className="form-control"
                    defaultValue={data.tx_port}
                    type="text"
                    onChange={(e) => {
                      setData({
                        ...data,
                        tx_port: e.target.value,
                      });
                    }}
                  />
                </div>
                <div className="form-group col-md-6">
                  <select
                    onChange={(e) => {
                      setData({
                        ...data,
                        tx: e.target.value,
                      });
                    }}
                    className="form-select"
                    value={data.tx}
                  >
                    <option value={1}> Select TR Bind </option>
                    <option value={1}> 1</option>
                    <option value={2}> 2</option>
                    <option value={3}> 3</option>
                    <option value={4}> 4</option>
                    <option value={5}> 5</option>
                    <option value={6}> 6</option>
                    <option value={7}> 7</option>
                    <option value={8}> 8</option>
                    <option value={9}> 9</option>
                    <option value={10}> 10</option>
                  </select>
                </div>
              </>
            )}
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-secondary"
              data-dismiss="modal"
            >
              Close
            </button>
            {isLoading ? (
              <button type="button" className="btn btn-primary">
                Loading.....
              </button>
            ) : (
              <>
                {props.type === "edit" ? (
                  <button
                    onClick={update}
                    type="button"
                    className="btn btn-primary"
                  >
                    Update
                  </button>
                ) : (
                  <button
                    onClick={addrouteAndRate}
                    type="button"
                    className="btn btn-primary"
                  >
                    Add Route
                  </button>
                )}
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default PopupVendor;
