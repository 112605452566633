import React, { useState, useEffect } from "react";
import AdminSideMenu from "../../navbar/AdminSideMenu";
import AdminTopMenu from "../../navbar/AdminTopMenu";
import { Link, useParams } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { URL } from "../../common/Url";
import { userid, token, type } from "../../common/AdminAuth";
import { addVendor, getVendorDetails } from "../../../api/api";

const AddVendor = () => {
  const { id } = useParams();
  const [managerList, setManagerList] = useState([]);

  const [error, seterror] = useState({
    errarStatus: false,
    errorMessage: "",
    bgcolor: "",
  });

  const [addCompany, setaddCompany] = useState({});

  let name, value;

  const inputsHandler = (e) => {
    name = e.target.name;
    value = e.target.value;
    setaddCompany({ ...addCompany, [name]: value });
  };

  const submitButton = (method) => {
    let NewCompany = { ...addCompany };
    NewCompany.method = method;
    NewCompany.user_id = userid;
    NewCompany.token = token;
    NewCompany.type = type;
    if (type === "Manager") {
      NewCompany.parent_id = userid;
    }
    console.log("NewCompany", NewCompany);
    addVendor(NewCompany).then((res) => {
      if (res.data.success === true) {
        seterror({
          errarStatus: true,
          errorMessage: res.data.message,
          bgcolor: "alert alert-success",
        });
        // setTimeout(() => {
        //   window.location.href = "/admin/vendor";
        // }, 1000);
      } else {
        seterror({
          errarStatus: true,
          errorMessage: res.data.message,
          bgcolor: "alert alert-danger",
        });
      }
    });
  };

  const getManagerList = () => {
    fetch(
      `${URL}manager.php?user_id=${userid}&method=retrieve&token=${token}`
    ).then((response) => {
      response.json().then((result) => {
        if (result.success === true) {
          setManagerList(result.data);
        } else {
        }
      });
    });
  };

  useEffect(() => {
    if (id) {
      getVendoerDetails(id);
      getManagerList();
    } else {
      getManagerList();
    }
  }, [id]);

  const getVendoerDetails = (id) => {
    getVendorDetails(id).then((res) => {
      console.log("res", res.data);
      let data = { ...res.data.data[0] };
      data.name = res.data.data[0].company_name;
      data.email = res.data.data[0].comp_email;
      data.mobile = res.data.data[0].comp_mobile;
      data.cus_ip = res.data.data[0].comp_ip;
      data.cus_port = res.data.data[0].comp_port;
      data.address = res.data.data[0].comp_address;
      data.company = res.data.data[0].company_name;
      data.parent_id = res.data.data[0].parent_id;
      setaddCompany(data);
    });
  };
  const managerHandeler = (e) => {
    let managerID = e.target.value;
    setaddCompany({ ...addCompany, parent_id: managerID });
  };

  const numbers = [];
  for (let i = -10; i <= 10; i++) {
    numbers.push(i);
  }

  return (
    <div className="layout-wrapper layout-content-navbar">
      <div className="layout-container">
        <AdminSideMenu id={id}/>

        <div className="layout-page">
          <AdminTopMenu />

          <div className="content-wrapper">
            <div className="container-xxl flex-grow-1 container-p-y">
              <p className="demo-inline-spacing text-right ">
                <Link className="btn btn-primary me-1" to="/admin/vendor">
                  <FontAwesomeIcon icon={faArrowLeft} /> Go Back
                </Link>
              </p>

              {error.errarStatus ? (
                <div className={error.bgcolor} role="alert">
                  <strong>Alert!</strong> {error.errorMessage}
                  <button
                    type="button"
                    className="close"
                    data-dismiss="alert"
                    aria-label="Close"
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
              ) : null}

              <div className="row">
                <div className="col-md-12">
                  <div className="card mb-4">
                    {id ? (
                      <h5 className="card-header">
                        {" "}
                        Edit or Update Vednor :{" "}
                        <span className="text-primary">
                          {addCompany.name}{" "}
                        </span>{" "}
                      </h5>
                    ) : (
                      <h5 className="card-header">Add New Vednor</h5>
                    )}
                    <hr className="m-0" />

                    <div className="card-body">
                      <div className="card pl-4 pr-4 pb-4 mt-2 mb-1">
                        <div className="row">
                          <div className="col-md-12">
                            <h6
                              className="pt-4 pb-4 pl-4 pr-4"
                              style={{
                                background: "#eee",
                                margin: "0px -25px",
                              }}
                            >
                              Company Details
                            </h6>
                          </div>

                          {type !== "Manager" && (
                            <div className="col-md-12 mt-3">
                              <select
                                onChange={managerHandeler}
                                value={addCompany.parent_id}
                                className="form-select"
                              >
                                <option value="">Select Manager</option>
                                {managerList.map((items) => (
                                  <option value={items.id}>{items.name}</option>
                                ))}
                              </select>
                            </div>
                          )}

                          <div className="col-md-6 mt-3">
                            <label>Company Name</label>
                            <div className="input-group">
                              <input
                                type="text"
                                className="form-control"
                                name="company"
                                value={addCompany.company}
                                onChange={inputsHandler}
                                placeholder="Enter Company Name"
                              />
                            </div>
                          </div>

                          <div className="col-md-6 mt-3">
                            <label>Company Short Name</label>
                            <div className="input-group">
                              <input
                                type="text"
                                className="form-control"
                                name="short_name"
                                value={addCompany.short_name}
                                onChange={inputsHandler}
                                placeholder="Enter Company Name"
                              />
                            </div>
                          </div>

                          <div className="col-md-6 mt-3">
                            <label>Company Signer Title</label>
                            <div className="input-group">
                              <input
                                type="text"
                                className="form-control"
                                name="signer_title"
                                value={addCompany.signer_title}
                                onChange={inputsHandler}
                                placeholder="Enter Company Signer Title"
                              />
                            </div>
                          </div>

                          <div className="col-md-6 mt-3">
                            <label>Company Signer Name</label>
                            <div className="input-group">
                              <input
                                type="text"
                                className="form-control"
                                name="signer_name"
                                value={addCompany.signer_name}
                                onChange={inputsHandler}
                                placeholder="Enter Company Signer Name"
                              />
                            </div>
                          </div>

                          <div className="col-md-6 mt-3">
                            <label>Email Address</label>
                            <div className="input-group">
                              <input
                                type="text"
                                className="form-control"
                                name="email"
                                value={addCompany.email}
                                onChange={inputsHandler}
                                placeholder="Example:www@gmail.com "
                              />
                            </div>
                          </div>

                          <div className="col-md-6 mt-3">
                            <label>Mobile Number</label>
                            <div className="input-group">
                              <input
                                type="text"
                                className="form-control"
                                name="mobile"
                                value={addCompany.mobile}
                                onChange={inputsHandler}
                                placeholder="Example:www@gmail.com "
                              />
                            </div>
                          </div>

                          <div className="col-md-12 mt-3">
                            <label>Address In Detail</label>
                            <div className="input-group">
                              <textarea
                                className="form-control"
                                name="address"
                                value={addCompany.address}
                                onChange={inputsHandler}
                                placeholder="Please Enter Full Address"
                              ></textarea>
                            </div>
                          </div>

                          <div className="col-md-12 mt-3">
                            <label>Postal Address</label>
                            <div className="input-group">
                              <textarea
                                className="form-control"
                                name="postal_address"
                                value={addCompany.postal_address}
                                onChange={inputsHandler}
                                placeholder="Please Postal Address"
                              ></textarea>
                            </div>
                          </div>

                          <div className="col-md-6 mt-3">
                            <label>Website URL </label>
                            <div className="input-group">
                              <input
                                type="text"
                                className="form-control"
                                name="website"
                                value={addCompany.website}
                                onChange={inputsHandler}
                                placeholder="www.gmail.com "
                              />
                            </div>
                          </div>

                          <div className="col-md-6 mt-3">
                            <label>Registration Number</label>
                            <div className="input-group">
                              <input
                                type="text"
                                className="form-control"
                                name="r_no"
                                value={addCompany.r_no}
                                onChange={inputsHandler}
                                placeholder="Example:www@gmail.com "
                              />
                            </div>
                          </div>

                          <div className="col-md-6 mt-3">
                            <label>VAT Number</label>
                            <div className="input-group">
                              <input
                                type="text"
                                className="form-control"
                                name="vat"
                                value={addCompany.vat}
                                onChange={inputsHandler}
                                placeholder="Example:SGDTSSHEY"
                              />
                            </div>
                          </div>

                          <div className="col-md-6 mt-3">
                            <label>Billing Term</label>
                            <div className="input-group">
                              <input
                                type="text"
                                className="form-control"
                                name="billing_term"
                                value={addCompany.billing_term}
                                onChange={inputsHandler}
                                placeholder="Example:Billing Term"
                              />
                            </div>
                          </div>

                          <div className="col-md-6 mt-3">
                            <label>Billing Time Zone</label>
                            <div className="input-group">
                              <input
                                type="text"
                                className="form-control"
                                name="billing_time"
                                value={addCompany.billing_time}
                                onChange={inputsHandler}
                                placeholder="Enter Billing time"
                              />
                            </div>
                          </div>

                          <div className="col-md-6 mt-3">
                            <label>Customer IP</label>
                            <div className="input-group">
                              <input
                                type="text"
                                className="form-control"
                                name="cus_ip"
                                value={addCompany.cus_ip}
                                onChange={inputsHandler}
                                placeholder="IP"
                              />
                            </div>
                          </div>

                          <div className="col-md-6 mt-3">
                            <label>Customer Port</label>
                            <div className="input-group">
                              <input
                                type="text"
                                className="form-control"
                                name="cus_port"
                                value={addCompany.cus_port}
                                onChange={inputsHandler}
                                placeholder="PORT"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="card-body">
                      <div className="card pl-4 pr-4 pb-4 mt-2 mb-1">
                        <div className="row">
                          <div className="col-md-12">
                            <h6
                              className="pt-4 pb-4 pl-4 pr-4 "
                              style={{
                                background: "#eee",
                                margin: "0px -25px",
                              }}
                            >
                              Bank Details
                            </h6>
                          </div>

                          <div className="col-md-6 mt-3">
                            <label>Account Name</label>
                            <div className="input-group">
                              <input
                                type="text"
                                className="form-control"
                                name="account_name"
                                value={addCompany.account_name}
                                onChange={inputsHandler}
                                placeholder="account_name"
                              />
                            </div>
                          </div>
                          <div className="col-md-6 mt-3">
                            <label>Bank Name</label>
                            <div className="input-group">
                              <input
                                type="text"
                                className="form-control"
                                name="bank_name"
                                value={addCompany.bank_name}
                                onChange={inputsHandler}
                                placeholder="bank_name"
                              />
                            </div>
                          </div>
                          <div className="col-md-12 mt-3">
                            <label> Bank Address</label>
                            <div className="input-group">
                              <textarea
                                name="bank_address"
                                className="form-control"
                                value={addCompany.bank_address}
                                onChange={inputsHandler}
                                placeholder="Bank Address"
                              ></textarea>
                            </div>
                          </div>
                          <div className="col-md-6 mt-3">
                            <label>Account Number</label>
                            <div className="input-group">
                              <input
                                type="text"
                                className="form-control"
                                name="account_number"
                                value={addCompany.account_number}
                                onChange={inputsHandler}
                                placeholder="account_number"
                              />
                            </div>
                          </div>
                          <div className="col-md-6 mt-3">
                            <label> Currency</label>
                            <div className="input-group">
                              <input
                                type="text"
                                className="form-control"
                                name="currency"
                                value={addCompany.currency}
                                onChange={inputsHandler}
                                placeholder="currency"
                              />
                            </div>
                          </div>
                          <div className="col-md-6 mt-3">
                            <label>SwiftCode/IBN Code</label>
                            <div className="input-group">
                              <input
                                type="text"
                                className="form-control"
                                name="siwft_code"
                                value={addCompany.siwft_code}
                                onChange={inputsHandler}
                                placeholder="siwft_code"
                              />
                            </div>
                          </div>
                          <div className="col-md-6 mt-3">
                            <label>Remarks</label>
                            <div className="input-group">
                              <input
                                type="text"
                                className="form-control"
                                name="remarks"
                                value={addCompany.remarks}
                                onChange={inputsHandler}
                                placeholder="remarks"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="card-body">
                      <div className="card pl-4 pr-4 pb-4 mt-2 mb-1">
                        <div className="row">
                          <div className="col-md-12">
                            <h6
                              className="pt-4 pb-4 pl-4 pr-4 "
                              style={{
                                background: "#eee",
                                margin: "0px -25px",
                              }}
                            >
                              Account Manager Details
                            </h6>
                          </div>

                          <div className="col-md-6 mt-3">
                            <label>Manage Name</label>
                            <div className="input-group">
                              <input
                                type="text"
                                className="form-control"
                                name="manage_name"
                                value={addCompany.manage_name}
                                onChange={inputsHandler}
                                placeholder="Manage Name"
                              />
                            </div>
                          </div>

                          <div className="col-md-6 mt-3">
                            <label>Manage Email</label>
                            <div className="input-group">
                              <input
                                type="text"
                                className="form-control"
                                name="manage_email"
                                value={addCompany.manage_email}
                                onChange={inputsHandler}
                                placeholder="Manage Email Address"
                              />
                            </div>
                          </div>

                          <div className="col-md-6 mt-3">
                            <label> Mobile</label>
                            <div className="input-group">
                              <input
                                type="text"
                                className="form-control"
                                name="manage_mobile"
                                value={addCompany.manage_mobile}
                                onChange={inputsHandler}
                                placeholder="+91 XXXXXXXX"
                              />
                            </div>
                          </div>

                          <div className="col-md-6 mt-3">
                            <label>Manage Skype</label>
                            <div className="input-group">
                              <input
                                type="text"
                                className="form-control"
                                name="manage_skype"
                                value={addCompany.manage_skype}
                                onChange={inputsHandler}
                                placeholder="Manage Skype "
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="card-body">
                      <div className="card pl-4 pr-4 pb-4 mt-2 mb-1">
                        <div className="row">
                          <div className="col-md-12">
                            <h6
                              className="pt-4 pb-4 pl-4 pr-4 "
                              style={{
                                background: "#eee",
                                margin: "0px -25px",
                              }}
                            >
                              Billing / Invoice
                            </h6>
                          </div>

                          <div className="col-md-6 mt-3">
                            <label>Billing Name</label>
                            <div className="input-group">
                              <input
                                type="text"
                                className="form-control"
                                name="billing_name"
                                value={addCompany.billing_name}
                                onChange={inputsHandler}
                                placeholder="Manage Name"
                              />
                            </div>
                          </div>

                          <div className="col-md-6 mt-3">
                            <label>Billing Email</label>
                            <div className="input-group">
                              <input
                                type="text"
                                className="form-control"
                                name="billing_email"
                                value={addCompany.billing_email}
                                onChange={inputsHandler}
                                placeholder="Manage Email Address"
                              />
                            </div>
                          </div>

                          <div className="col-md-6 mt-3">
                            <label>Billing Mobile</label>
                            <div className="input-group">
                              <input
                                type="text"
                                className="form-control"
                                name="billing_mobile"
                                value={addCompany.billing_mobile}
                                onChange={inputsHandler}
                                placeholder="+91 XXXXXXXX"
                              />
                            </div>
                          </div>

                          <div className="col-md-6 mt-3">
                            <label>Billing Skype</label>
                            <div className="input-group">
                              <input
                                type="text"
                                className="form-control"
                                name="billing_skype"
                                value={addCompany.billing_skype}
                                onChange={inputsHandler}
                                placeholder="Manage Skype "
                              />
                            </div>
                          </div>
                          <div className="col-md-6 mt-3">
                            <label>Time Zone (gmt)</label>
                            <div className="input-group">
                             <select
                                onChange={inputsHandler}
                                value={addCompany.time_zone}
                                className="form-select"
                              >
                                <option value="">Select Time Zone</option>
                                {numbers.map((number, index) => (
                                  <option key={index} value={number}>{number}</option>
                                ))}
                              </select>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="card-body">
                      <div className="card pl-4 pr-4 pb-4 mt-2 mb-1">
                        <div className="row">
                          <div className="col-md-12">
                            <h6
                              className="pt-4 pb-4 pl-4 pr-4 "
                              style={{
                                background: "#eee",
                                margin: "0px -25px",
                              }}
                            >
                              Rate Nofication
                            </h6>
                          </div>

                          <div className="col-md-6 mt-3">
                            <label>Rate Name</label>
                            <div className="input-group">
                              <input
                                type="text"
                                className="form-control"
                                name="rate_name"
                                value={addCompany.rate_name}
                                onChange={inputsHandler}
                                placeholder="Rate Name"
                              />
                            </div>
                          </div>

                          <div className="col-md-6 mt-3">
                            <label>Rate Email</label>
                            <div className="input-group">
                              <input
                                type="text"
                                className="form-control"
                                name="rate_email"
                                value={addCompany.rate_email}
                                onChange={inputsHandler}
                                placeholder="Rate email address"
                              />
                            </div>
                          </div>

                          <div className="col-md-6 mt-3">
                            <label>Rate Mobile</label>
                            <div className="input-group">
                              <input
                                type="text"
                                className="form-control"
                                name="rate_mobile"
                                value={addCompany.rate_mobile}
                                onChange={inputsHandler}
                                placeholder="+91 XXXXXXXX"
                              />
                            </div>
                          </div>

                          <div className="col-md-6 mt-3">
                            <label>Rate Skype</label>
                            <div className="input-group">
                              <input
                                type="text"
                                className="form-control"
                                name="rate_skype"
                                value={addCompany.rate_skype}
                                onChange={inputsHandler}
                                placeholder="Rate Skype "
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="card-body">
                      <div className="card pl-4 pr-4 pb-4 mt-2 mb-1">
                        <div className="row">
                          <div className="col-md-12">
                            <h6
                              className="pt-4 pb-4 pl-4 pr-4 "
                              style={{
                                background: "#eee",
                                margin: "0px -25px",
                              }}
                            >
                              Support/NOC
                            </h6>
                          </div>

                          <div className="col-md-6 mt-3">
                            <label>Support Name</label>
                            <div className="input-group">
                              <input
                                type="text"
                                className="form-control"
                                name="support_name"
                                value={addCompany.support_name}
                                onChange={inputsHandler}
                                placeholder="Support name"
                              />
                            </div>
                          </div>

                          <div className="col-md-6 mt-3">
                            <label>Support Email</label>
                            <div className="input-group">
                              <input
                                type="text"
                                className="form-control"
                                name="support_email"
                                value={addCompany.support_email}
                                onChange={inputsHandler}
                                placeholder="Support email address"
                              />
                            </div>
                          </div>

                          <div className="col-md-6 mt-3">
                            <label>Support Mobile</label>
                            <div className="input-group">
                              <input
                                type="text"
                                className="form-control"
                                name="support_mobile"
                                value={addCompany.support_mobile}
                                onChange={inputsHandler}
                                placeholder="+91 XXXXXXXX"
                              />
                            </div>
                          </div>

                          <div className="col-md-6 mt-3">
                            <label>Support Skype</label>
                            <div className="input-group">
                              <input
                                type="text"
                                className="form-control"
                                name="support_skype"
                                value={addCompany.support_skype}
                                onChange={inputsHandler}
                                placeholder="Support Skype "
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="card-body">
                      <div className="card pl-4 pr-4 pb-4 mt-2 mb-1">
                        <div className="row">
                          <div className="col-md-12">
                            <h6
                              className="pt-4 pb-4 pl-4 pr-4 "
                              style={{
                                background: "#eee",
                                margin: "0px -25px",
                              }}
                            >
                              Dispute NOC
                            </h6>
                          </div>

                          <div className="col-md-6 mt-3">
                            <label>disp_name</label>
                            <div className="input-group">
                              <input
                                type="text"
                                className="form-control"
                                name="disp_name"
                                value={addCompany.disp_name}
                                onChange={inputsHandler}
                                placeholder="disp_name"
                              />
                            </div>
                          </div>

                          <div className="col-md-6 mt-3">
                            <label>disp_email</label>
                            <div className="input-group">
                              <input
                                type="text"
                                className="form-control"
                                name="disp_email"
                                value={addCompany.disp_email}
                                onChange={inputsHandler}
                                placeholder="Example:www@gmail.com "
                              />
                            </div>
                          </div>

                          <div className="col-md-6 mt-3">
                            <label>disp_mobile</label>
                            <div className="input-group">
                              <input
                                type="text"
                                className="form-control"
                                name="disp_mobile"
                                value={addCompany.disp_mobile}
                                onChange={inputsHandler}
                                placeholder="+91 XXXXXXXX"
                              />
                            </div>
                          </div>

                          <div className="col-md-6 mt-3">
                            <label>disp_skype</label>
                            <div className="input-group">
                              <input
                                type="text"
                                className="form-control"
                                name="disp_skype"
                                value={addCompany.disp_skype}
                                onChange={inputsHandler}
                                placeholder="disp_skype"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="col-md-12 pb-4 text-right">
                      {id ? (
                        <button
                          type="button"
                          onClick={() => submitButton("update")}
                          className="btn btn-primary"
                        >
                          Update Details
                        </button>
                      ) : (
                        <button
                          type="button"
                          onClick={() => submitButton("create")}
                          className="btn btn-primary"
                        >
                          Add Vendor
                        </button>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddVendor;
