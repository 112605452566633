import React, { useState, useEffect } from "react";
import AdminSideMenu from "../../navbar/AdminSideMenu";
import AdminTopMenu from "../../navbar/AdminTopMenu";
import { deleteRateList, getRouteAndRateList } from "../../../api/api";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import AddRouteRatePopup from "../../common/AddRouteRatePopup";
import DeletePopup from "../../common/DeletePopup";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft, faPlus } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import { numberOnly } from "../../Utils/Helper";
import AddRouterateBulk from "../../common/AddRouterateBulk";
import { useAsync } from "react-select/async";

const RateAndRouteList = () => {
  let { systemID } = useParams();

  const [error, setError] = useState({
    errorStatus: false,
    errorMessage: "",
    bgcolor: "",
  });

  const [routeAndrateList, setRouteAndrateList] = useState([]);
  const [updateID, setUpdateID] = useState({
    id: "",
    systemID: "",
  });
  const [selectedSystemID, setSelectedSystemID] = useState();
  const [popupType, setPopupType] = useState(null);
  const [addPrice,setAddPrice] = useState(false)
  const [updatePrice,setUpdatePrice] = useState(false)
 

  const getUserDetails = () => {
    getRouteAndRateList(systemID).then((res) => {
      if (res.data.success === true) {
        setRouteAndrateList(res.data.data);
      } else {
        setError({
          errorStatus: true,
          errorMessage: res.data.message,
          bgcolor: "alert alert-danger",
        });
      }
    });
  };

  useEffect(() => {
    getUserDetails();
  }, []);

  const handleAddPriceClick = () => {
    setAddPrice(true)
    setSelectedSystemID(systemID);
  };

  console.log(selectedSystemID)
  const handleUpdatePriceClick = (id, systemID) => {
    setUpdatePrice(true)
    setUpdateID({ id, systemID });
  };

  const yesDelete = () => {
    deleteRateList(updateID.id, updateID.systemID).then((res) => {
      if (res.data.success === true) {
        setError({
          errorStatus: true,
          errorMessage: res.data.message,
          bgcolor: "alert alert-success",
        });
        setTimeout(() => {
          window.location.href = `/admin/user/rate-route-list/${systemID}`;
        }, 1000);
      } else {
        setError({
          errorStatus: true,
          errorMessage: res.data.message,
          bgcolor: "alert alert-danger",
        });
      }
    });
  };

  return (
    <div className="layout-wrapper layout-content-navbar">
      <div className="layout-container">
        <AdminSideMenu systemID={systemID} />

        <div className="layout-page">
          <AdminTopMenu />

          <div className="content-wrapper">
            <div className="container-xxl container-p-y">
              <div className="row">
                <div className="col-lg-12 col-md-12 order-1">
                  {error.errorStatus ? (
                    <div className={error.bgcolor} role="alert">
                      <strong>Alert!</strong> {error.errorMessage}
                      <button
                        type="button"
                        className="close"
                        data-dismiss="alert"
                        aria-label="Close"
                      >
                        <span aria-hidden="true">&times;</span>
                      </button>
                    </div>
                  ) : null}

                  <div className="">
                    <div className="card">
                      <h5 className="card-header ">
                        Route and rate list of{" "}
                        <span className="text-success">{systemID}</span>
                        <span className="demo-inline-spacing float-right ">
                          <Link
                            className="btn btn-primary me-1"
                            to="/admin/user-list"
                          >
                            <FontAwesomeIcon icon={faArrowLeft} /> Back to route
                          </Link>
                        </span>
                        <span
                          data-toggle="modal"
                          className="demo-inline-spacing float-right  btn btn-primary me-1 "
                          data-target="#bulkupload"
                          onClick={() => setSelectedSystemID(systemID)}
                        >
                          <FontAwesomeIcon icon={faPlus} /> Add Price Bulk
                        </span>
                        <span
                          data-toggle="modal"
                          className="demo-inline-spacing float-right  btn btn-primary me-1 "
                          data-target="#exampleModalCenter"
                          onClick={handleAddPriceClick}
                        >
                          <FontAwesomeIcon icon={faPlus} /> Add Price
                        </span>
                      </h5>
                      <div className="table-responsive text-nowrap">
                        <table className="table">
                          <thead>
                            <tr>
                              <th> Sender ID </th>
                              <th> System ID </th>
                              <th> Price </th>
                              <th> Country Code </th>
                              <th> Route</th>
                              <th>Action</th>
                            </tr>
                          </thead>
                          <tbody className="table-border-bottom-0">
                            {routeAndrateList.map((items, i) => (
                              <tr key={i}>
                                <td>
                                  <span className="text-primary">
                                    {items.source_regex}
                                  </span>
                                </td>
                                <td>{items.system_id}</td>
                                <td>{items.cost}</td>
                                <td>
                                  {items.country_name}(
                                  {numberOnly(items.regex)})
                                </td>

                                <td>{items.smsc_id}</td>

                                <td>
                                  <span
                                    role="button"
                                    data-toggle="modal"
                                    data-target="#exampleModalCenter"
                                    className="badge bg-label-primary me-1"
                                    onClick={(e) =>
                                      handleUpdatePriceClick(
                                        items.route_id,
                                        items.system_id
                                      )
                                    }
                                  >
                                    Update Price
                                  </span>
                                  <span
                                    role="button"
                                    data-toggle="modal"
                                    data-target="#delete"
                                    className="badge bg-label-danger me-1"
                                    onClick={(e) =>
                                      setUpdateID({
                                        id: items.route_id,
                                        systemID: items.system_id,
                                      })
                                    }
                                  >
                                    Delete
                                  </span>
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

     { updatePrice && <AddRouteRatePopup
        type="edit"
        id={updateID.id}
        selectedSystemID={updateID.systemID}
      />}
    {addPrice && <AddRouteRatePopup
      type="add"
      selectedType={true}
      selectedSystemID={selectedSystemID}
    />}
      <DeletePopup yesDelete={yesDelete} />

      <AddRouterateBulk selectedSystemID={selectedSystemID} />
    </div>
  );
};

export default RateAndRouteList;
