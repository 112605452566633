import React, { useState, useEffect } from "react";
import AdminSideMenu from "../../navbar/AdminSideMenu";
import AdminTopMenu from "../../navbar/AdminTopMenu";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEdit,
  faEye,
  faPlus,
  faTrashAlt,
} from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import { getCompanyListAPI } from "../../../api/api";
import SearchBox from "../../common/SearchBox";

const Company = () => {
  const [error, seterror] = useState({
    errarStatus: false,
    errorMessage: "",
    bgcolor: "",
  });

  const [companyList, setcompanyList] = useState([]);
  const [searchData, setSearchData] = useState([]);

  const getCompanyList = () => {
    getCompanyListAPI().then((res) => {
      //console.log("res", res.data);
      if (res.data.success === true) {
        setcompanyList(res.data.data);
        setSearchData(res.data.data);
      } else {
        setcompanyList([]);
      }
    });
  };

  useEffect(() => {
    getCompanyList();
  }, []);

  return (
    <div className="layout-wrapper layout-content-navbar">
      <div className="layout-container">
        <AdminSideMenu />

        <div className="layout-page">
          <AdminTopMenu />

          <div className="content-wrapper">
            <div className="container-xxl container-p-y">
              <p className="d-flex justify-content-end">
                <Link className="btn btn-primary" to="/admin/add-company">
                  <FontAwesomeIcon icon={faPlus} /> Add New Company
                </Link>
              </p>

              <div className="row">
                <div className="col-lg-12 col-md-4 order-1">
                  {error.errarStatus ? (
                    <div className={error.bgcolor} role="alert">
                      <strong>Alert!</strong> {error.errorMessage}
                      <button
                        type="button"
                        className="close"
                        data-dismiss="alert"
                        aria-label="Close"
                      >
                        <span aria-hidden="true">&times;</span>
                      </button>
                    </div>
                  ) : null}

                  <div className="">
                    <div className="d-flex justify-content-between items-align-center">
                      <h5 className="card-header pl-0">Customer Company List</h5>
                      <SearchBox
                        setSearchData={setSearchData}
                        matchType={"company"}
                        alldata={companyList}
                        data={searchData}
                      />
                    </div>
                    <div className="card">
                      <div className="table-responsive text-nowrap">
                        <table style={{ minHeight: '200px' }} className="table">
                          <thead>
                            <tr>
                              <th>Actions</th>
                              <th>Company Name</th>
                              <th>Manager</th>
                              <th>Email</th>
                              <th>Mobile</th>

                              <th>Customer IP</th>

                              <th>No of Users</th>
                              <th>Status</th>
                            </tr>
                          </thead>
                          <tbody className="table-border-bottom-0">
                            {searchData.map((items, i) => (
                              <tr key={i}>
                                <td>
                                  <span className="badge bg-primary mr-2">
                                    <Link
                                      to={
                                        "/admin/add-company/view-edit/" +
                                        items.id
                                      }
                                    >
                                      <FontAwesomeIcon icon={faEdit} />
                                    </Link>
                                  </span>

                                  <span role="button" className="badge bg-info">
                                    <Link
                                      to={"/admin/company/users/" + items.id}
                                    >
                                      <FontAwesomeIcon icon={faEye} />
                                    </Link>
                                  </span>
                                </td>
                                <td>{items.name}</td>
                                <td>{items.manager}</td>
                                <td>{items.email}</td>
                                <td>{items.mobile}</td>
                                <td>{items.cust_ip}</td>

                                <td>
                                  <Link to={"/admin/user-list/" + items.id}>
                                    {items.usercount}
                                  </Link>
                                </td>

                                <td>
                                  {items.status === 1 ? (
                                    <span className="badge bg-label-primary me-1">
                                      Active
                                    </span>
                                  ) : (
                                    <span className="badge bg-label-danger me-1">
                                      Inactive
                                    </span>
                                  )}
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Company;
