import React, { useEffect, useState } from 'react';
import { bililingAPi, getCompanyAPI, getMasterCountryList, getVendorDetails, vendorbililingAPi } from '../../../api/api';
import { getCountryName } from '../../Utils/Helper';
import { useAsync } from 'react-select/async';

const BillingPDF = (props) => {
    const [data, setData] = useState([])
    const [userBillingData, setUserBillingData] = useState([])
    const [countryList, setCountryList] = useState([]);
    const [companyDetails, setCompanyDetails] = useState([])
    const [vendorDetails, setVendorDetails] = useState([])
    const [vendorData, setVendorData] = useState([])
    const [vendorBillingData,setVendorBillingData] = useState([])

    useEffect(() => {
        BillingData();
        fecthCompanyData();
        VendorBillingData();
        fecthVendorDetails();
    }, [])

    useEffect(() => {
        getMasterCountryList().then((res) => {
            if (res.data.success === true) {
                setCountryList(res.data.data);
            } else {
                setCountryList([]);
            }
        });
    }, []);

    const BillingData = async () => {
        try {
            const result = await bililingAPi(props.method, props.DateFrom, props.DateTo, props.selectedCompanyID);
            console.log("result.data.data", result.data.data);
            setData(result.data.data);
            createNewObject(result.data.data)
        } catch (error) {
            console.error('Error fetching data:', error);
            // Handle error state if needed
        }
    };

    const VendorBillingData = async () => {
        try {
            let result = await vendorbililingAPi(props.Vendormethod, props.VendorDateFrom, props.VendorDateTo, props.VendorselectedCompanyID);
            console.log("result.data.data", result.data.data)
            setVendorData(result.data.data)
            createVendorObject(result.data.data)
        } catch (error) {
            console.log(error)
        }
    }

    const createNewObject = (data) => {
        let overallTotalCostbyUser = 0;
        let overallTotalSubmissionbyUser = 0;

        let userResult = {};

        // Assuming data is properly defined and accessible
        Object.keys(data).map((key) => {
            let mainKeyTotalCost = 0;
            let totalSubmission = 0;

            Object.keys(data[key]).map((x) => {
                let totalCost = parseFloat(data[key][x].totalcost);
                mainKeyTotalCost += totalCost;

                // Correct calculation for total submissions
                let submissions = data[key][x];
                let delivered = parseFloat(submissions.delivered) || 0;
                let failed = parseFloat(submissions.failed) || 0;
                let other = parseFloat(submissions.other) || 0;

                let submission = delivered + failed + other;
                totalSubmission += submission;
            });

            overallTotalCostbyUser += mainKeyTotalCost;
            overallTotalSubmissionbyUser += totalSubmission;
        });

        // Assigning values to userResult
        userResult.overallTotalSubmissionbyUser = overallTotalSubmissionbyUser;
        userResult.overallTotalCostbyUser = overallTotalCostbyUser.toFixed(5);
        userResult.other = data; // Assuming you want to include the entire data for reference

        // Check if userResult has any data before setting state
        if (Object.keys(userResult).length > 0) {
            // Assuming setUserBillingData is a function to set state
            setUserBillingData(userResult);
        }
    };

    const date = new Date();

    // Extract date components
    const hours = date.getHours();
    const minutes = date.getMinutes();
    const day = date.getDate();
    const month = date.getMonth() + 1; // Months are zero-indexed, so January is 0
    const year = date.getFullYear();

    // Pad single digits with leading zeros
    const formattedHours = hours.toString().padStart(2, '0');
    const formattedMinutes = minutes.toString().padStart(2, '0');
    const formattedDay = day.toString().padStart(2, '0');
    const formattedMonth = month.toString().padStart(2, '0');

    // Create the formatted date string in the desired format
    const formattedDate = `${formattedDay}/${formattedMonth}/${year}`;

    // Output the formatted date

    const fecthCompanyData = async () => {
        try {
            let response = await getCompanyAPI(props.selectedCompanyID)
            console.log(response.data.data[0])
            setCompanyDetails(response.data.data[0])
        } catch (error) {
            console.log(error)
        }
    }

    const fecthVendorDetails = async () => {
        try {
            let response = await getVendorDetails(props.VendorselectedCompanyID)
            console.log(response.data.data[0])
            setVendorDetails(response.data.data[0])
        } catch (error) {
            console.log(error)
        }
    }

    const createVendorObject = (data) => {
        let overallTotalCost = 0;
        let overallTotalSubmission = 0;
    
        const result = {};
    
        Object.keys(data).forEach((key) => {
          let mainKeyTotalCost = 0;
          let totalSubmission = 0;
    
          Object.keys(data[key]).forEach((x) => {
            Object.keys(data[key][x]).forEach((cost) => {
              let totalCost = parseFloat(data[key][x][cost].totalcost);
              mainKeyTotalCost += totalCost;
    
              // Correct calculation for total submissions
              let submissions = data[key][x][cost];
              let delivered = parseFloat(submissions.delivered) || 0;
              let failed = parseFloat(submissions.failed) || 0;
              let other = parseFloat(submissions.other) || 0;
    
              let submission = delivered + failed + other;
              totalSubmission += submission;
            });
          });
    
          // Accumulate main key total cost
          overallTotalCost += mainKeyTotalCost;
          overallTotalSubmission += totalSubmission;
        });
    
    
        console.log("Overall Total Cost:", overallTotalCost);
        console.log("Overall Total Submission", overallTotalSubmission);
    
    
        result.overallSubmission = overallTotalSubmission;
        result.overallTotalCost = overallTotalCost.toFixed(5);
        result.other = data
    
        console.log("result", result);
        if (Object.keys(result).length > 0) {
          // Set the result object to the billingData state
          setVendorBillingData(result);
        }
    
      };
      console.log(vendorBillingData)



    return (
        <>
        <div className='container'>
            <div className='row' style={{ backgroundColor: 'white', padding: '20px' }}>
                <div className='col-lg-12'>
                    <div className='row'>
                        <div className='col-md-6'>
                            <h1><span style={{ color: 'green' }}>DATA</span><span style={{ color: 'blue' }}>GEN</span></h1>
                            <p style={{ marginTop: '-20px', color: 'black' }}><b>DATAGEN INTERNET SERVICES PRIVATE LIMITED</b></p>
                            <p style={{ fontSize: '13px', marginTop: '-20px' }}>1211-1212 BEST SKY TOWER NETAJI SUBHASH PLACE</p>
                            <p style={{ fontSize: '13px', marginTop: '-20px' }}>PITAM PURA 110034</p>
                            <p style={{ fontSize: '13px', marginTop: '-20px' }}>Delhi 110034</p>
                            <p style={{ fontSize: '13px', marginTop: '-20px' }}>India</p>
                            <p style={{ fontSize: '13px', marginTop: '-20px' }}>GSTIN: 07AAGCD1543A1Z9</p>
                            <div>
                                <p style={{ marginTop: '70px' }}>Bill To</p>
                                <p style={{ color: 'black', marginTop: '-20px' }}><b>{companyDetails.name ? companyDetails.name : vendorDetails.short_name}</b></p>
                                <p style={{ marginTop: '-20px' }}>{companyDetails.address ? companyDetails.address : vendorDetails.comp_address}</p>
                                <p style={{ color: 'black', marginTop: '-20px' }}><b>{companyDetails.mobile ? companyDetails.mobile : vendorDetails.comp_mobile}</b></p>
                            </div>
                        </div>
                        <div className='col-md-6'>
                            <div style={{ marginLeft: "130px" }}>
                                <h2 className='text-center' style={{ marginLeft: "90px", color: "black" }}>Tax Invoice</h2>
                                <p style={{ marginLeft: '120px', marginTop: '-15px' }}># DIS/2024-25/0026</p>
                                <div style={{ color: 'black', marginLeft: "200px" }}>
                                    <p style={{ fontSize: "13px" }}><b>Balance Due</b></p>
                                    <p style={{ marginTop: "-20px" }}><b>1,00,000</b></p>
                                </div>
                                <div style={{ marginTop: "60px", marginLeft: "40px" }}>
                                    <p style={{ marginTop: '20px' }}>Invoice Date: <span style={{ marginLeft: "70px", color: 'black' }}>{formattedDate}</span></p>
                                    <p>Terms: <span style={{ marginLeft: "90px", color: 'black' }}>Due to Receipt</span></p>
                                    <p>Due Date: <span style={{ marginLeft: "80px", color: 'black' }}>17/06/24</span></p>
                                </div>
                            </div>
                        </div>
                    </div>
                    {props.method ? (
                        <>
                            <div className='row' style={{ marginTop: '40px' }}>
                                <div className='col-md-12'>
                                    <table className='table table-striped table-bordered'>
                                        <thead className='thead-dark'>
                                            <tr>
                                                <th style={{ color: "white" }}>User Name</th>
                                                <th style={{ color: "white" }}>Country</th>
                                                <th style={{ color: "white" }}>Delivered</th>
                                                <th style={{ color: "white" }}>Failed</th>
                                                <th style={{ color: "white" }}>Other</th>
                                                <th style={{ color: "white" }}>Total Submission</th>
                                                <th style={{ color: "white" }}>SMS Cost</th>
                                                <th style={{ color: "white" }}>Total Cost</th>
                                            </tr>
                                        </thead>
                                        <tbody>

                                            {Object.keys(data).map((username) => (
                                                Object.keys(data[username]).map((x) => {
                                                    let smscost = data[username][x].smscost
                                                    let totalcost = data[username][x].totalcost

                                                    let TotalCost = totalcost.toFixed(5)
                                                    return (
                                                        <>
                                                            <tr key={`${username}-${x}`}>
                                                                <td style={{ fontSize: "13px" }}>{username}</td>
                                                                <td style={{ fontSize: "13px" }}> {getCountryName(countryList, x)}</td>
                                                                <td style={{ fontSize: "13px" }}>{data[username][x].delivered || 0}</td>
                                                                <td style={{ fontSize: "13px" }}>{data[username][x].failed || 0}</td>
                                                                <td style={{ fontSize: "13px" }}>{data[username][x].other || 0}</td>
                                                                <td style={{ fontSize: "13px" }}>{parseInt(data[username][x].delivered || 0) + parseInt(data[username][x].failed || 0) + parseInt(data[username][x].other || 0)}</td>
                                                                <td style={{ fontSize: "13px" }}>{smscost}</td>
                                                                <td style={{ fontSize: "13px" }}>{TotalCost}</td>
                                                            </tr>
                                                        </>
                                                    )

                                                })
                                            ))}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </>
                    ) : (
                        <>
                            <div className='row' style={{ marginTop: '40px' }}>
                                <div className='col-md-12'>
                                    <table className='table table-striped table-bordered'>
                                        <thead className='thead-dark'>
                                            <tr>
                                                <th style={{ color: "white" }}>User Name</th>
                                                <th style={{ color: "white" }}>Country</th>
                                                <th style={{ color: "white" }}>Delivered</th>
                                                <th style={{ color: "white" }}>Failed</th>
                                                <th style={{ color: "white" }}>Other</th>
                                                <th style={{ color: "white" }}>Total Submission</th>
                                                <th style={{ color: "white" }}>Vendor Cost</th>
                                                <th style={{ color: "white" }}>Total Cost</th>
                                            </tr>
                                        </thead>
                                        <tbody>

                                            {Object.keys(vendorData).map((username) => (
                                                Object.keys(vendorData[username]).map((x) => (
                                                    Object.keys(vendorData[username][x]).map((vendor) => {
                                                        const data = vendorData[username][x][vendor];
                                                        const vendorcost = data.vendor_cost;
                                                        const totalcost = data.totalcost;
                                                        const TotalCost = totalcost.toFixed(5);

                                                        return (
                                                            <tr key={`${username}-${x}-${vendor}`}>
                                                                <td style={{ fontSize: "13px" }}>{username}</td>
                                                                <td style={{ fontSize: "13px" }}>{getCountryName(countryList, x)}</td>
                                                                <td style={{ fontSize: "13px" }}>{data.delivered || 0}</td>
                                                                <td style={{ fontSize: "13px" }}>{data.failed || 0}</td>
                                                                <td style={{ fontSize: "13px" }}>{data.other || 0}</td>
                                                                <td style={{ fontSize: "13px" }}>
                                                                    {parseInt(data.delivered || 0) + parseInt(data.failed || 0) + parseInt(data.other || 0)}
                                                                </td>
                                                                <td style={{ fontSize: "13px" }}>{vendorcost}</td>
                                                                <td style={{ fontSize: "13px" }}>{TotalCost}</td>
                                                            </tr>
                                                        );
                                                    })
                                                ))
                                            ))}

                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </>
                    )}


                    <div className='row' style={{ marginTop: "20px" }}>
                        <div className='col-md-12 text-right'>
                            <p style={{ color: 'black'}}>Total Cost:  <span style={{ marginLeft: '30px' }}><b>{userBillingData.overallTotalCostbyUser? userBillingData.overallTotalCostbyUser : vendorBillingData.overallTotalCost}</b></span></p>
                            <p style={{ color: 'black' }}>Total Submission:  <span style={{ marginLeft: '30px', marginTop: "-10px" }}><b>{userBillingData.overallTotalSubmissionbyUser? userBillingData.overallTotalSubmissionbyUser :vendorBillingData.overallSubmission}</b></span></p>
                            <p style={{ color: 'black' }}><b>Total <span style={{ marginLeft: '50px' }}>$695,03</span></b></p>
                            <p style={{ color: 'black', backgroundColor: '#d3d3d3', padding: '10px', display: 'inline-block' }}><b>Balance Due <span style={{ marginLeft: '20px' }}>$695,03</span></b></p>
                        </div>
                    </div>

                    <div className='row' style={{ marginTop: '20px' }}>
                        <div className='col-md-12'>
                            <p>Notes</p>
                            <p style={{ color: 'black', fontSize: '12px' }}>PAN :- AAGCD1543A</p>
                            <p style={{ color: 'black', fontSize: '12px', marginTop: "-20px" }}>CIN:- U74999DL2017PTC316874</p>
                            <p style={{ color: 'black', fontSize: '12px' }}>Bank Details • Bank Account Number : 135305001121 • Bank Branch IFSC : ICICI Bank • Branch IFSC Code : ICIC0001353</p>
                        </div>
                    </div>
                    <hr style={{ marginTop: '20px', marginBottom: '20px', borderColor: 'black' }} />
                </div>
            </div>
        </div>
        </>
    );
}

export default BillingPDF;
