import React, { useState, useEffect } from "react";
import AdminSideMenu from "../../navbar/AdminSideMenu";
import AdminTopMenu from "../../navbar/AdminTopMenu";
import { retrieveUserAssignedRoute } from "../../../api/api";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import AddRouteRatePopup from "../../common/AddRouteRatePopup";
import DeletePopup from "../../common/DeletePopup";
import { numberOnly } from "../../Utils/Helper";
import SearchBox from "../../common/SearchBox";
import DataTable from "react-data-table-component";
import { customTableStylesForSummery } from "../../common/customTableStyles";
const UserAssignedRoute = () => {
  let { systemID } = useParams();

  const [error, seterror] = useState({
    errarStatus: false,
    errorMessage: "",
    bgcolor: "",
  });

  const [routeAndrateList, setRouteAndrateList] = useState([]);
  const [updateID, setupdateID] = useState({id:"",systemID: ""});
  const [searchData, setSearchData] = useState([]);
  const getUserDetails = () => {
    retrieveUserAssignedRoute().then((res) => {
      console.log("res.data.data", res.data.data);
      if (res.data.success === true) {
        setRouteAndrateList(res.data.data);
        setSearchData(res.data.data);
      } else {
        seterror({
          errarStatus: true,
          errorMessage: res.data.message,
          bgcolor: "alert alert-danger",
        });
      }
    });
  };

  useEffect(() => {
    getUserDetails();
  }, []);

  

  const columns = [
    {
      name: "Sender ID",
      selector: (row) => row.source_regex,
      sortable: true,
    },
    {
      name: "System ID ",
      selector: (row) => row.system_id,
      sortable: true,
    },
    {
      name: "Price",
      selector: (row) => row.cost,
      sortable: true,
    },
    {
      name: "Country Code",
      selector: (row) => row.country_name + " (" + row.country_code + ")",
      sortable: true,
      wrap: true,
    },
    {
      name: "Route",
      selector: (row) => row.smsc_id,
      sortable: true,
    },
    {
      name: "Action",
      cell: (e) => (
        <span
          role="button"
          data-toggle="modal"
          data-target="#exampleModalCenter"
          className="badge bg-label-primary me-1"
          onClick={() => setupdateID({id: e.route_id , systemID: e.system_id})}
        >
          Update Price
        </span>
      ),
      sortable: true,
    },
  ];
  console.log(updateID)


  return (
    <div className="layout-wrapper layout-content-navbar">
      <div className="layout-container">
        <AdminSideMenu />

        <div className="layout-page">
          <AdminTopMenu />

          <div className="content-wrapper">
            <div className="container-xxl container-p-y">
              <div className="row">
                <div className="col-lg-12 col-md-4 order-1">
                  {error.errarStatus ? (
                    <div className={error.bgcolor} role="alert">
                      <strong>Alert!</strong> {error.errorMessage}
                      <button
                        type="button"
                        className="close"
                        data-dismiss="alert"
                        aria-label="Close"
                      >
                        <span aria-hidden="true">&times;</span>
                      </button>
                    </div>
                  ) : null}

                  <div className="">
                    <div className="d-flex justify-content-between items-align-center">
                      <h5 className="card-header pl-0">
                        {" "}
                        Route and rate list{" "}
                      </h5>
                      <SearchBox
                        setSearchData={setSearchData}
                        matchType={"system_id"}
                        alldata={routeAndrateList}
                        data={searchData}
                      />
                    </div>
                    <div className="card">
                      <DataTable
                        pagination
                        handleSort
                        columns={columns}
                        data={searchData}
                        customStyles={customTableStylesForSummery}
                        noDataComponent="No balance History"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <AddRouteRatePopup
        type={"edit"}
        id={updateID.id}
        selectedSystemID={updateID.systemID}
      />
      <DeletePopup systemID={systemID} id={updateID} />
    </div>
  );
};

export default UserAssignedRoute;
