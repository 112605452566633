import { create } from "zustand";
import { getMasterCountryList } from "../api/api";

const useMasterCountryList = create((set, get) => {
  return {
    countryList: "",
  };
});

export default useMasterCountryList;
