import React, { useState, useEffect } from "react";
import AdminSideMenu from "../../navbar/AdminSideMenu";
import AdminTopMenu from "../../navbar/AdminTopMenu";
import { URL } from "../../common/Url";
import { userid, token, type } from "../../common/AdminAuth";
import "react-datepicker/dist/react-datepicker.css";
import DataTable from "react-data-table-component";
import { customTableStylesForSummery } from "../../common/customTableStyles";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faEye } from "@fortawesome/free-solid-svg-icons";
import BulkUploadRoute from "../vendor/components/BulkUploadRoute";
import RouteDetailsPopup from "./components/RouteDetailsPopup";
import SearchBox from "../../common/SearchBox";
import PopupVendor from "../vendor/components/PopupVendor";

const AllRoutes = () => {
  const [error, seterror] = useState({
    errarStatus: false,
    errorMessage: "",
    bgcolor: "",
  });

  const [selectedRoute, setSelectedRoute] = React.useState();
  const [pending, setPending] = React.useState(true);
  const [routeList, setrouteList] = useState([]);
  const [searchData, setSearchData] = useState([]);
  const [bulkUploadPrice, setBulkUploadPrice] = useState({
    manager_id: "",
    verdor_name: "",
  });

 

  const [vendorData, setVendorData] = useState({
    manager_id: "",
    parent_id: "",
    verdor_name: "",
  });

  useEffect(() => {
    fetch(
      `${URL}route.php?user_id=${userid}&method=retrieve_all&token=${token}&user_type=${type}`
    ).then((response) => {
      response.json().then((result) => {
        //console.log("res", result.data);
        if (result.success === true) {
          setrouteList(result.data);
          setSearchData(result.data);
          setPending(false);
        } else {
          setPending(true);
        }
      });
    });
  }, []);

  const columns = [
    {
      name: "Action",
      cell: (row) => (
        <div className="dropdown">
          <span
            type="button"
            id="dropdownMenuButton"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="currentColor"
              className="bi bi-three-dots-vertical"
              viewBox="0 0 16 16"
            >
              <path d="M9.5 13a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0z" />
            </svg>
          </span>
          <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
            <a
              className="dropdown-item"
              data-target="#bulkuploadRoute"
              data-toggle="modal"
              role="button"
              onClick={() =>
                setBulkUploadPrice({
                  id: row.id,
                  verdor_name: row.title,
                })
              }
            >
              <FontAwesomeIcon icon={faEye} /> Upload Price
            </a>
            <a
              href={`/admin/vendor/price-list/${row.id}/${row.title}`}
              className="dropdown-item"
            >
              <FontAwesomeIcon icon={faEye} /> View Price
            </a>
            <a
              data-toggle="modal"
              data-target="#vendor"
              className="dropdown-item"
              onClick={() =>
                setVendorData({
                  manager_id: row.id,
                  parent_id: row.parent_id,
                  verdor_name: row.company_name,
                })
              }
            >
              <FontAwesomeIcon icon={faEdit} /> Edit Route
            </a>
          </div>
        </div>
      ),
      sortable: true,
      width: "100px",
    },
    {
      name: "Route Name",
      selector: (row) => (
        <span
          role="button"
          data-target="#roteDetailsPopup"
          data-toggle="modal"
          className="text-primary"
          onClick={() => setSelectedRoute(row.id)}
        >
          {row.title}
        </span>
      ),
      sortable: true,
    },
    {
      name: "Company",
      selector: (row) => row.company,
      sortable: true,
    },
    {
      name: "Manager",
      selector: (row) => row.manager,
      sortable: true,
    },
    {
      name: "Status",
      selector: (row) =>
        row.status === 1 ? (
          <span className="badge bg-label-success me-1">Active</span>
        ) : (
          <span className="badge bg-label-danger me-1">Inactive</span>
        ),
      sortable: true,
    },
    {
      name: "Created",
      selector: (row) => row.created,
      sortable: true,
    },
  ];


  return (
    <div className="layout-wrapper layout-content-navbar">
      <div className="layout-container">
        <AdminSideMenu />

        <div className="layout-page">
          <AdminTopMenu />

          <div className="content-wrapper">
            <div className="container-xxl container-p-y">
              <div className="row">
                <div className="col-lg-12 col-md-4 order-1">
                  {error.errarStatus ? (
                    <div className={error.bgcolor} role="alert">
                      {error.errorMessage}
                    </div>
                  ) : null}
                </div>
              </div>

              <div className="">
                <div className="d-flex justify-content-between align-items-center">
                  <h5 className="card-header pl-0">All Router List</h5>
                  <SearchBox
                    setSearchData={setSearchData}
                    matchType={"title"}
                    alldata={routeList}
                    data={searchData}
                  />
                </div>
                <div className="card">
                  <div style={{ minHeight: '200px' }} className="card-body">
                    <DataTable
                      pagination
                      handleSort
                      columns={columns}
                      data={searchData}
                      customStyles={customTableStylesForSummery}
                      noDataComponent="No Route List"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <BulkUploadRoute bulkUploadPrice={bulkUploadPrice} />
      <RouteDetailsPopup id={selectedRoute} />
      <PopupVendor type="edit" vendorData={vendorData} />
    </div>
  );
};

export default AllRoutes;
