import React, { useState, useEffect } from "react";
import AdminSideMenu from "../../navbar/AdminSideMenu";
import AdminTopMenu from "../../navbar/AdminTopMenu";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowLeft,
  faEdit,
  faEye,
  faPlus,
  faTrashAlt,
} from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import { getCompanyListAPI, getCompanyWiseUsers } from "../../../api/api";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";

const CompanyUsers = () => {
  let { id } = useParams();
  const [error, seterror] = useState({
    errarStatus: false,
    errorMessage: "",
    bgcolor: "",
  });

  const [companyList, setcompanyList] = useState([]);

  const getCompanyList = () => {
    getCompanyWiseUsers(id).then((res) => {
      console.log("res", res.data);
      if (res.data.success === true) {
        setcompanyList(res.data.data);
      } else {
        setcompanyList([]);
      }
    });
  };

  useEffect(() => {
    getCompanyList();
  }, []);

  return (
    <div className="layout-wrapper layout-content-navbar">
      <div className="layout-container">
        <AdminSideMenu id={id}/>

        <div className="layout-page">
          <AdminTopMenu />

          <div className="content-wrapper">
            <div className="container-xxl container-p-y">
              <div className="row">
                <div className="col-lg-12 col-md-4 order-1">
                  {error.errarStatus ? (
                    <div className={error.bgcolor} role="alert">
                      <strong>Alert!</strong> {error.errorMessage}
                      <button
                        type="button"
                        className="close"
                        data-dismiss="alert"
                        aria-label="Close"
                      >
                        <span aria-hidden="true">&times;</span>
                      </button>
                    </div>
                  ) : null}

                  <div className="">
                    <div className="card">
                      <h5 className="card-header">
                        Users List
                        <span className="demo-inline-spacing float-right ">
                          <Link
                            className="btn btn-primary me-1"
                            to="/admin/company"
                          >
                            <FontAwesomeIcon icon={faArrowLeft} /> Go Back
                          </Link>
                        </span>
                      </h5>
                      <div className="table-responsive text-nowrap">
                        <table className="table">
                          <thead>
                            <tr>
                              <th>Company Name</th>
                              <th>credit</th>
                              <th>throughput</th>

                              <th>max_binds</th>

                              <th>Status</th>
                              <th>Actions Set As per user List</th>
                            </tr>
                          </thead>
                          <tbody className="table-border-bottom-0">
                            {companyList.map((items, i) => (
                              <tr key={i}>
                                <td>{items.system_id}</td>
                                <td>{items.credit}</td>
                                <td>{items.throughput}</td>
                                <td>{items.max_binds}</td>

                                <td>
                                  {items.status === 1 ? (
                                    <span className="badge bg-label-primary me-1">
                                      Active
                                    </span>
                                  ) : (
                                    <span className="badge bg-label-danger me-1">
                                      Inactive
                                    </span>
                                  )}
                                </td>

                                <td>
                                  <span className="badge bg-primary mr-2">
                                    <Link
                                      to={
                                        "/admin/add-company/view-edit/" +
                                        items.id
                                      }
                                    >
                                      <FontAwesomeIcon icon={faEdit} />
                                    </Link>
                                  </span>

                                  <span role="button" className="badge bg-info">
                                    <Link
                                      to={
                                        "/admin/add-company/view-edit/" +
                                        items.id
                                      }
                                    >
                                      <FontAwesomeIcon icon={faEye} />
                                    </Link>
                                  </span>
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CompanyUsers;
