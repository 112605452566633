import React, { useState } from "react";
import { token, type, userid } from "../../component/common/AdminAuth";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDownload } from "@fortawesome/free-solid-svg-icons";
import { URL } from "./Url";
import {useHistory} from "react-router-dom";
function AddRouterateBulk(props) {
  const history = useHistory();
  const [fileUrl, setFileURL] = useState();
  const [isLoading, setIsLoading] = useState(false);

  const [error, seterror] = useState({
    errarStatus: false,
    errorMessage: "",
    bgcolor: "",
  });

  const addrouteAndRate = () => {
    setIsLoading(true);
    const data = new FormData();
    data.append("amdfile", fileUrl);
    data.append("user_id", userid);
    data.append("system_id", props.selectedSystemID);
    data.append("token", token);
    data.append("method", "upload");
    //console.log(data);
    fetch(`${URL}/user_pricelist.php`, {
      method: "POST",
      body: data,
    }).then((result) => {
      result.json().then((resp) => {
        //console.log("resp", resp);
        if (resp.success === true) {
          seterror({
            errarStatus: true,
            errorMessage: resp.message,
            bgcolor: "alert alert-success",
          });
          setIsLoading(false);
          history.push(`/admin/user/rate-route-list/${props.selectedSystemID}`)
          window.scrollTo(0, 0);
          setTimeout(() => {
            window.location.reload(true)
          }, 1000);
        } else {
          seterror({
            errarStatus: true,
            errorMessage: resp.message,
            bgcolor: "alert alert-danger",
          });
          setIsLoading(false);
        }
      });
    });
  };

  const fileChangedHandler = (e) => {
    if (e.target.files[0]) {
      setFileURL(e.target.files[0]);
    }
  };


  return (
    <div
      className="modal fade"
      id="bulkupload"
      tabIndex="-1"
      role="dialog"
      aria-labelledby="exampleModalbulkupload"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-centered" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="exampleModalLong">
              Bulk Add Rate / Route for{" "}
              <span className="text-success">{props.selectedSystemID} </span>
            </h5>
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body row">
            <div className="col-md-12">
              {error.errarStatus ? (
                <div className={error.bgcolor} role="alert">
                  <strong>Alert!</strong> {error.errorMessage}
                  <button
                    type="button"
                    className="close"
                    data-dismiss="alert"
                    aria-label="Close"
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
              ) : null}
            </div>
            <a
              className="mb-3 text-right d-block"
              download
              href="/rateCsvSampleUser.csv"
            >
              <FontAwesomeIcon icon={faDownload} /> Dowload Sample
            </a>
            <div className="form-group col-md-12">
              <input
                placeholder="Enter mcc"
                className="form-control"
                type="file"
                onChange={fileChangedHandler}
              />
            </div>
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-secondary"
              data-dismiss="modal"
            >
              Close
            </button>
            {isLoading ? (
              <button type="button" className="btn btn-primary">
                Uploading.....
              </button>
            ) : (
              <button
                onClick={addrouteAndRate}
                type="button"
                className="btn btn-primary"
              >
                Bulk Upload
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default AddRouterateBulk;
