import React, { useState } from "react";

function DeletePopup(props) {
  const [isLoading, setIsLoading] = useState(false);

  return (
    <div
      className="modal fade"
      id="delete"
      tabIndex="-1"
      role="dialog"
      aria-labelledby="exampleModaldelete"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-centered" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="exampleModalLong">
              Do You want to delete
              <span className="text-success"> </span>
            </h5>
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-secondary"
              data-dismiss="modal"
            >
              No
            </button>
            {isLoading ? (
              <button type="button" className="btn btn-primary">
                Uploading.....
              </button>
            ) : (
              <button
                onClick={() => props.yesDelete()}
                type="button"
                className="btn btn-primary"
              >
                Yes
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default DeletePopup;
