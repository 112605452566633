import React, { useState, useEffect } from "react";
import AdminSideMenu from "../../navbar/AdminSideMenu";
import AdminTopMenu from "../../navbar/AdminTopMenu";
import useSelectedDate from "../../../store/UseFilterDateStore";
import DatePicker from "react-datepicker";
import Select from 'react-select'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch, faTriangle } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import { getUserDetailsAPI } from "../../../api/api";

const List = () => {
  var date = new Date();
  var todatdate =
    date.getFullYear() +
    "-" +
    (date.getMonth() + 1) +
    "-" +
    ("0" + date.getDate()).slice(-2);
  const setStoreDateFrom = useSelectedDate((state) => state.setStoreDateFrom);
  const setStoreDateTo = useSelectedDate((state) => state.setDateTo);

  const selctedDateFrom = useSelectedDate((state) => state.selctedDateFrom);
  const selctedDateTo = useSelectedDate((state) => state.selctedDateTo);

  const [DateFrom, setDateFrom] = useState(selctedDateFrom);
  const [DateTo, setDateTo] = useState(selctedDateTo);
  const [showDateFrom, setshowDateFrom] = useState(new Date());
  const [showDateTo, setshowDateTo] = useState(new Date());
  const [selectedUser, setselectedUser] = useState("");
  const [isloading, setisloading] = useState(false);
  const [userData, setUserData] = useState([]);
  const [listData, setListData] = useState([])
  const [order, setOrder] = useState("ASC")

  let fetchData = async () => {
    let data = await fetch("https://smppintl.datagenit.com/apismpp/v1/download_centre.php?user_id=1&method=retrieve&token=b8860908f2cf45f721a40d23f2e291f9&user_type=Admin")
    let resData = await data.json()
    console.log(resData.data)
    setListData(resData.data)
  }

  useEffect(() => {
    getUserDetails();
    fetchData();
  }, []);


  const getUserDetails = async () => {
    getUserDetailsAPI().then((res)=>{
      console.log(res.data)
      if(res.data.success){
        setUserData(res.data.data)
      }
    })
  }

  const sorting = (col) => {
    if (order === 'ASC') {
      const sorted = [...listData].sort((a, b) =>
        a[col]?.toLowerCase() > b[col]?.toLowerCase() ? 1 : -1
      )
      setListData(sorted);
      setOrder("DSC")
    }
    if (order === 'DSC') {
      const sorted = [...listData].sort((a, b) =>
        a[col]?.toLowerCase() < b[col]?.toLowerCase() ? 1 : -1
      )
      setListData(sorted);
      setOrder("ASC")
    }
  }


  const handleUserChange = (selectedOption) => {
    setselectedUser(selectedOption);
  };
  return (
    <div className="layout-wrapper layout-content-navbar">
      <div className="layout-container">
        <AdminSideMenu />
        <div className="layout-page">
          <AdminTopMenu />
          <div className="content-wrapper">
            <div className="container-xxl container-p-y">
              <div className="row">
                <div className="">
                  <div className="container-p-y">
                    <div className="">
                      <div className="card">
                        <h5 className="card-header">Account Wise Summary</h5>
                        <div className="table-responsive text-nowrap">

                          <table className="table">
                            <thead>
                              <tr>
                                <th style={{cursor:"pointer"}} onClick={()=>sorting("user_id")}>User Name</th>
                                <th style={{cursor:"pointer"}} onClick={()=>sorting("startdate")}>From Date</th>
                                <th style={{cursor:"pointer"}} onClick={()=>sorting("enddate")}>To Date</th>
                                <th style={{cursor:"pointer"}} onClick={()=>sorting("created_date")}>Created Date</th>
                                <th>Download Link </th>
                              </tr>
                            </thead>
                            <tbody className="table-border-bottom-0">
                              {listData.map((item, i) => {
                                return <>
                                  <tr key={i}>
                                    <td>
                                      <span className="text-primary">
                                        {item.user_id}
                                      </span>
                                    </td>
                                    <td>
                                      <span className="text-primary">
                                        {item.startdate}
                                      </span>
                                    </td>
                                    <td>
                                      <span className="text-primary">
                                        {item.enddate}
                                      </span>
                                    </td>
                                    <td>
                                      <span className="text-primary">
                                        {item.created_date}
                                      </span>
                                    </td>

                                    <td>
                                    {item.linkfordownload ?
                                      (<span className="badge bg-info mr-2">
                                          <a
                                          href={"https://smppintl.datagenit.com/report/downloadcenter/" + item.linkfordownload}
                                        >
                                          Click Here to Download
                                        </a>
                                      </span>)
                                      : (<span  className="badge mr-2" style={{backgroundColor:"orange"}} >Please Wait</span>)} 
                                    </td>

                                  </tr>
                                </>
                              })}
                            </tbody>
                          </table>

                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default List
