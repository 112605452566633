import { getMasterCountryList } from "../../api/api";

export const numberOnly = (text) => {
  const regex = /\d+/g;
  const match = text.match(regex);
  let number;
  if (match) {
    number = match[0];
  }
  return number;
};

export const dateFormate = (date) => {
  let newdate =
    date.getFullYear() +
    "-" +
    (date.getMonth() + 1) +
    "-" +
    ("0" + date.getDate()).slice(-2);
  return newdate;
};

export const decodeMessage = (message) => {
  return message;
};

export const getCountryName = (companyList, countryCode) => {
  try {
    let contryNameData = companyList.find(
      (item) => parseInt(item.country_code) === parseInt(countryCode)
    );
    return contryNameData
      ? contryNameData.country_name + " (" + contryNameData.country_code + ")"
      : contryNameData.country_code;
  } catch (err) {
    console.log(err);
  }
};

export const setCookie = (cookieName, cookieValue, expiryDate) => {
  const date = new Date();
  date.setTime(date.getTime() + expiryDate * 24 * 60 * 1000);
  let expries = "expires=" + date.toUTCString();
  document.cookie = cookieName + "=" + cookieValue + ";" + expries + ";path=/";
};

export const deleteCookie = (cookieName, cookieValue) => {
  document.cookie =
    cookieName +
    "=" +
    cookieValue +
    ";path=" +
    "/" +
    ";expires = Thu, 01 Jan 1970 00:00:01 GMT";
};

export const objectFlatten = (obj) => {
  let result = {}
  for (const i in obj) {
    if (typeof obj[i] === "object" && !Array.isArray(obj[i])) {
      const temp = objectFlatten(obj[i])
      for (const j in temp) {
        result[i + "." + j] = temp[j]
      }
    } else {
      result[i] = obj[i]
    }
  }
  return result
} 
