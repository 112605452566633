import React, { useState, useEffect } from "react";
import AdminSideMenu from "../../navbar/AdminSideMenu";
import AdminTopMenu from "../../navbar/AdminTopMenu";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEdit,
  faEye,
  faPlus,
  faTrashAlt,
} from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import { getAllVendorList } from "../../../api/api";
import PopupVendor from "./components/PopupVendor";
import BulkUploadRoute from "./components/BulkUploadRoute";
import SearchBox from "../../common/SearchBox";

const Vendor = () => {
  const [error, seterror] = useState({
    errarStatus: false,
    errorMessage: "",
    bgcolor: "",
  });
  const [searchData, setSearchData] = useState([]);
  const [vendorList, setVendorList] = useState([]);
  const [vendorData, setVendorData] = useState({
    manager_id: "",
    parent_id: "",
    verdor_name: "",
  });

  const getCompanyList = () => {
    getAllVendorList().then((res) => {
      //console.log("res.data", res.data);
      if (res.data.success === true) {
        setVendorList(res.data.data);
        setSearchData(res.data.data);
      } else {
        setVendorList([]);
      }
    });
  };

  console.log(searchData)

  useEffect(() => {
    getCompanyList();
  }, []);

  return (
    <div className="layout-wrapper layout-content-navbar">
      <div className="layout-container">
        <AdminSideMenu />

        <div className="layout-page">
          <AdminTopMenu />

          <div className="content-wrapper">
            <div className="container-xxl container-p-y">
              <p className="d-flex justify-content-end">
                <Link className="btn btn-primary" to="/admin/add-vendor">
                  <FontAwesomeIcon icon={faPlus} /> Add New Vendor
                </Link>
              </p>

              <div className="row">
                <div className="col-lg-12 col-md-4 order-1">
                  {error.errarStatus ? (
                    <div className={error.bgcolor} role="alert">
                      <strong>Alert!</strong> {error.errorMessage}
                      <button
                        type="button"
                        className="close"
                        data-dismiss="alert"
                        aria-label="Close"
                      >
                        <span aria-hidden="true">&times;</span>
                      </button>
                    </div>
                  ) : null}

                  <div className="">
                    <div className="d-flex justify-content-between items-align-center">
                      <h5 className="card-header pl-0">Vendor Company List</h5>
                      <SearchBox
                        setSearchData={setSearchData}
                        matchType={"vendor"}
                        alldata={vendorList}
                        data={searchData}
                      />
                    </div>
                    <div className="card">
                      <div className="table-responsive text-nowrap">
                        <table style={{ minHeight: '200px' }} className="table">
                          <thead>
                            <tr>
                              <th>Actions</th>
                              <th>Vendor Name</th>
                              <th>short_name</th>
                              <th>Manager </th>
                              <th>Email</th>
                              <th>Mobile</th>

                              <th>Customer IP</th>
                              <th>Customer Port</th>

                              <th>Status</th>
                            </tr>
                          </thead>
                          <tbody className="table-border-bottom-0">
                            {searchData.map((items, i) => (
                              <tr key={i}>
                                <td>
                                  <div className="dropdown">
                                    <span
                                      type="button"
                                      id="dropdownMenuButton"
                                      data-toggle="dropdown"
                                      aria-haspopup="true"
                                      aria-expanded="false"
                                    >
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="16"
                                        height="16"
                                        fill="currentColor"
                                        className="bi bi-three-dots-vertical"
                                        viewBox="0 0 16 16"
                                      >
                                        <path d="M9.5 13a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0z" />
                                      </svg>
                                    </span>
                                    <div
                                      className="dropdown-menu"
                                      aria-labelledby="dropdownMenuButton"
                                    >
                                      <a
                                        className="dropdown-item"
                                        data-toggle="modal"
                                        data-target="#vendor"
                                        onClick={() =>
                                          setVendorData({
                                            manager_id: items.id,
                                            parent_id: items.parent_id,
                                            verdor_name: items.company_name,
                                          })
                                        }
                                      >
                                        <FontAwesomeIcon icon={faPlus} /> Add
                                        Route Single
                                      </a>
                                      <a
                                        className="dropdown-item"
                                        href={`/admin/vendor/view-edit/${items.id}`}
                                      >
                                        <FontAwesomeIcon icon={faEdit} /> Edit
                                      </a>
                                      <a
                                        href={`/admin/vendor/route/${items.id}/${items.company_name}`}
                                        className="dropdown-item"
                                      >
                                        <FontAwesomeIcon icon={faEye} /> View
                                        Route
                                      </a>
                                    </div>
                                  </div>
                                </td>
                                <td>{items.company_name}</td>
                                <td>{items.short_name}</td>
                                <td>{items.manager}</td>
                                <td>{items.comp_email}</td>
                                <td>{items.comp_mobile}</td>
                                <td>{items.comp_ip}</td>
                                <td>{items.comp_port}</td>

                                <td>
                                  {items.status === 1 ? (
                                    <span className="badge bg-label-primary me-1">
                                      Active
                                    </span>
                                  ) : (
                                    <span className="badge bg-label-danger me-1">
                                      Inactive
                                    </span>
                                  )}
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <PopupVendor vendorData={vendorData} />
    </div>
  );
};

export default Vendor;
