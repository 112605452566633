import React, { useState, useEffect } from "react";
import AdminSideMenu from "../../navbar/AdminSideMenu";
import AdminTopMenu from "../../navbar/AdminTopMenu";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPlus,
  faLockOpen,
  faEye,
  faCreditCard,
  faEdit,
} from "@fortawesome/free-solid-svg-icons";
import { Link, useParams } from "react-router-dom";
import { URL } from "../../common/Url";
import { userid, token, type } from "../../common/AdminAuth";
import AddRouteRatePopup from "../../common/AddRouteRatePopup";
import AddRouterateBulk from "../../common/AddRouterateBulk";
import SearchBox from "../../common/SearchBox";

const UsersList = () => {
  let { id } = useParams();

  const [error, seterror] = useState({
    errarStatus: false,
    errorMessage: "",
    bgcolor: "",
  });

  const [permisionDetails, setPermisionDetails] = useState({
    create_user: "",
    view_user: "",
    update_user: "",
    delete_user: "",
  });

  const [userList, setuserList] = useState([]);
  const [searchData, setSearchData] = useState([]);
  const [selectedSystemID, setSelectedSystemID] = useState();
  const [order, setOrder] = useState("ASC")

  const getUserDetails = () => {
    fetch(
      `${URL}users-as.php?user_id=${userid}&method=retrieve_new&token=${token}&user_type=${type}`
    ).then((response) => {
      response.json().then((result) => {
        console.log("user List", result);
        if (result.success === true) {
          setuserList(result.data);
          setSearchData(result.data);
        } else {
          seterror({
            errarStatus: true,
            errorMessage: result.message,
            bgcolor: "alert alert-danger",
          });
        }
      });
    });
  };

  const FilterUsersDetails = (id) => {
    fetch(
      `${URL}users-as.php?user_id=${userid}&method=retrieve_new&token=${token}&user_type=${type}`
    ).then((response) => {
      response.json().then((result) => {
        //console.log('FilterUsersDetails',result);
        if (result.success === true) {
          setuserList(
            result.data.filter(function (freelancer) {
              return freelancer.manager_id == id;
            })
          );
        } else {
          seterror({
            errarStatus: true,
            errorMessage: result.message,
            bgcolor: "alert alert-danger",
          });
        }
      });
    });
  };

  useEffect(() => {
    if (id) {
      FilterUsersDetails(id);
    } else {
      getUserDetails();
    }
    getPermisionDetails();
  }, []);

  const getPermisionDetails = () => {
    fetch(
      `${URL}manager.php?user_id=${userid}&method=retrieveid&id=${userid}&token=${token}&user_type=${type}`
    ).then((response) => {
      response.json().then((result) => {
        //console.log(result);
        if (result.success === true) {
          setPermisionDetails({
            create_user: result.data[0].create_user,
            view_user: result.data[0].view_user,
            update_user: result.data[0].update_user,
            delete_user: result.data[0].delete_user,
          });
        } else {
          seterror({
            errarStatus: true,
            errorMessage: result.message,
            bgcolor: "alert alert-danger",
          });
        }
      });
    });
  };

  const login = (e) => {
    fetch(
      `${URL}users.php?user_id=${userid}&method=retrieve_profile&id=${e}&token=${token}&user_type=${type}`
    ).then((response) => {
      response.json().then((result) => {
        //console.log('result', result);
        if (result.success === true) {
          loginAction(result.data[0].username, result.data[0].password);
        } else {
          seterror({
            errarStatus: true,
            errorMessage: result.message,
            bgcolor: "alert alert-danger",
          });
        }
      });
    });
  };

  const loginAction = (username, password) => {
    let loginDetal = {
      username: username,
      password: password,
      token: token,
      user_type: type,
    };

    fetch(`${URL}login.php`, {
      method: "post",
      headers: {
        "content-Type": "application/json",
      },
      body: JSON.stringify(loginDetal),
    }).then((result) => {
      result.json().then((result) => {
        if (result.success === true) {
          seterror({
            errarStatus: true,
            errorMessage: "Success",
            bgcolor: "alert alert-success",
          });
          localStorage.setItem("user", JSON.stringify(result));
          setTimeout(function () {
            window.location = "/dashboard";
          }, 1000);
        } else {
          seterror({
            errarStatus: true,
            errorMessage: result.message,
            bgcolor: "alert alert-danger",
          });
        }
      });
    });
  };

  const userDisable = (id, status) => {
    seterror({ errarStatus: false });
    fetch(
      `${URL}users-as.php?user_id=${userid}&method=change_status&id=${id}&status=${status}`
    ).then((response) => {
      response.json().then((result) => {
        // console.log(result);
        if (result.success === true) {
          seterror({
            errarStatus: true,
            errorMessage: result.message,
            bgcolor: "alert alert-success",
          });
          setTimeout(function () {
            getUserDetails();
          }, 1000);
        } else {
          seterror({
            errarStatus: true,
            errorMessage: result.message,
            bgcolor: "alert alert-danger",
          });
        }
      });
    });
  };

  const sorting = (col) => {
    if (order === 'ASC') {
      const sorted = [...searchData].sort((a, b) =>
        a[col]?.toLowerCase() > b[col]?.toLowerCase() ? 1 : -1
      )
      setSearchData(sorted);
      setOrder("DSC")
    }
    if (order === 'DSC') {
      const sorted = [...searchData].sort((a, b) =>
        a[col]?.toLowerCase() < b[col]?.toLowerCase() ? 1 : -1
      )
      setSearchData(sorted);
      setOrder("ASC")
    }
  }
  

  return (
    <div className="layout-wrapper layout-content-navbar">
      <div className="layout-container">
        <AdminSideMenu />

        <div className="layout-page">
          <AdminTopMenu />

          <div className="content-wrapper">
            <div className="container-xxl container-p-y">
              {permisionDetails.create_user === 1 ? (
                <p className="demo-inline-spacing text-right ">
                  <Link className="btn btn-primary me-1" to="/admin/add-user">
                    <FontAwesomeIcon icon={faPlus} /> Add New User
                  </Link>
                </p>
              ) : null}

              <div className="row">
                <div className="col-lg-12 col-md-12 order-1">
                  {error.errarStatus ? (
                    <div className={error.bgcolor} role="alert">
                      <strong>Alert!</strong> {error.errorMessage}
                      <button
                        type="button"
                        className="close"
                        data-dismiss="alert"
                        aria-label="Close"
                      >
                        <span aria-hidden="true">&times;</span>
                      </button>
                    </div>
                  ) : null}

                  <div className="">
                    <div className="d-flex justify-content-between items-align-center">
                      <h5 className="card-header pl-0">User List</h5>
                      <SearchBox
                        setSearchData={setSearchData}
                        matchType={"user"}
                        alldata={userList}
                        data={searchData}
                      />
                    </div>
                    <div className="card">
                      <div
                        style={{ minHeight: "300px" }}
                        className="table-responsive text-nowrap"
                      >
                        <table className="table">
                          <thead>
                            <tr>
                              {type === "Admin" ? <th> Login </th> : null}
                              <th>Action</th>
                              <th style={{cursor:"pointer"}} onClick={() => sorting("system_id")}> User Name</th>
                              <th> credit</th>
                              {type !== "Manager" && <th style={{cursor:"pointer"}} onClick={()=> sorting("manager")}> Manager </th>}
                              <th style={{cursor:"pointer"}} onClick={()=> sorting("company")}> Company </th>
                              <th> Route </th>
                              <th>Whitelist IP</th>
                              <th>Acccount </th>
                              <th>Status</th>
                            </tr>
                          </thead>
                          <tbody className="table-border-bottom-0">
                            {searchData.map((items, i) => (
                              <tr key={i}>
                                {type === "Admin" ? (
                                  <td className="border-right">
                                    {" "}
                                    <FontAwesomeIcon
                                      onClick={() => login(items.username)}
                                      title="Login to user account"
                                      className="login-button"
                                      icon={faLockOpen}
                                      color="green"
                                    />{" "}
                                  </td>
                                ) : null}

                                <td>
                                  <div className="dropdown">
                                    <span
                                      type="button"
                                      id="dropdownMenuButton"
                                      data-toggle="dropdown"
                                      aria-haspopup="true"
                                      aria-expanded="false"
                                    >
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="16"
                                        height="16"
                                        fill="currentColor"
                                        className="bi bi-three-dots-vertical"
                                        viewBox="0 0 16 16"
                                      >
                                        <path d="M9.5 13a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0z" />
                                      </svg>
                                    </span>
                                    <div
                                      className="dropdown-menu"
                                      aria-labelledby="dropdownMenuButton"
                                    >
                                      <a
                                        className="dropdown-item"
                                        data-toggle="modal"
                                        data-target="#exampleModalCenter"
                                        onClick={() =>
                                          setSelectedSystemID(items.system_id)
                                        }
                                      >
                                        <FontAwesomeIcon icon={faPlus} /> Add Price
                                      </a>
                                      <a
                                        className="dropdown-item"
                                        data-toggle="modal"
                                        data-target="#bulkupload"
                                        onClick={() =>
                                          setSelectedSystemID(items.system_id)
                                        }
                                      >
                                        <FontAwesomeIcon icon={faPlus} /> Add Price Bulk
                                      </a>

                                      <a
                                        href={`/admin/user/rate-route-list/${items.system_id}`}
                                        className="dropdown-item"
                                      >
                                        <FontAwesomeIcon icon={faEye} /> View
                                        Price List
                                      </a>

                                      <a
                                        href={`/admin/add-user/edit/${items.system_id}`}
                                        className="dropdown-item"
                                      >
                                        <FontAwesomeIcon icon={faEdit} /> Edit
                                      </a>

                                      <a
                                        href={`/admin/add-balance/${items.system_id}/${items.credit}`}
                                        className="dropdown-item"
                                      >
                                        <FontAwesomeIcon icon={faCreditCard} />{" "}
                                        Add Balance{" "}
                                      </a>
                                    </div>
                                  </div>
                                </td>

                                <td>
                                  {" "}
                                  <span className="text-primary">
                                    {items.system_id}{" "}
                                  </span>
                                </td>

                                <td>
                                  {" "}
                                  <span className="text-primary">
                                    {items.credit}{" "}
                                  </span>
                                </td>

                                {type !== "Manager" && (
                                  <td>
                                    <span className="text-primary">
                                      {items.manager}{" "}
                                    </span>
                                  </td>
                                )}

                                <td>
                                  <span className="text-primary">
                                    {items.company}{" "}
                                  </span>
                                </td>

                                <td>{items.default_smsc}</td>
                                <td>{items.connect_allow_ip}</td>
                                <td>
                                  <span className="badge bg-label-primary me-1">
                                    {items.enable_prepaid_billing === 0
                                      ? "postpaid"
                                      : "prepaid"}
                                  </span>
                                </td>

                                <td>
                                  {items.status === 1 ? (
                                    <span className="badge bg-label-success me-1">
                                      Active
                                    </span>
                                  ) : (
                                    <span className="badge bg-label-danger me-1">
                                      Inactive
                                    </span>
                                  )}
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <AddRouteRatePopup type={"add"} selectedSystemID={selectedSystemID} />
      <AddRouterateBulk selectedSystemID={selectedSystemID} />
    </div>
  );
};

export default UsersList;
