import React, { useEffect, useState } from "react";
import { numberOnly } from "../Utils/Helper";

function SearchBox(props) {
  const search = (text) => {
    let data = props.alldata.filter((item) => {
      const regex = new RegExp(`${text.trim()}`, "gi");
      if (regex == "/(?:)/gi") {
        return props.alldata;
      } else {
        if (props.matchType === "title") {
          return item.title.match(regex);
        }
        if (props.matchType === "system_id") {
          return item.system_id.match(regex) || item.smsc_id.match(regex) || item.regex.match(regex);
        }
        if (props.matchType === "user") {
          return item.system_id.match(regex) || item.manager.match(regex) || item.company.match(regex) || item.connect_allow_ip.match(regex);
        }
        if (props.matchType === "company") {
          return item.name.match(regex) || item.manager.match(regex) || item.email.match(regex) || item.mobile.match(regex) || item.cust_ip.match(regex);
        }
        if (props.matchType === "vendor") {
          return item.company_name.match(regex) || item.manager.match(regex) || item.comp_mobile.match(regex) || item.comp_ip.match(regex);
        }
      }
    });
    props.setSearchData(data);
  };
  const reset = () => {
    props.setSearchData(props.alldata);
  };

  return (
    <div className="d-flex gap-2 align-items-center">
      <input
        type="text"
        onChange={(e) => search(e.target.value)}
        placeholder={`Search ${props.matchType}`}
        className="form-control  h-45"
      />
      <button onClick={() => reset()} className="btn btn-danger">
        Reset
      </button>
    </div>
  );
}

export default SearchBox;
