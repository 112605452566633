import axios from "axios";
import { token, type, userid } from "../component/common/AdminAuth";
import { URL } from "../component/common/Url";

export const getCompanyListAPI = () => {
  if (type === "Manager") {
    return axios.get(
      `${URL}company.php?user_id=${userid}&method=retrieve&token=${token}&type=${type}&parent_id=${userid}`
    );
  } else {
    return axios.get(
      `${URL}company.php?user_id=${userid}&method=retrieve&token=${token}&type=${type}`
    );
  }
};

export const addCompanyAPI = (data) => {
  return axios.post(`${URL}company.php`, data);
};

export const getCompanyWiseUsers = (id) => {
  return axios.get(
    `${URL}users-as.php?user_id=${userid}&method=retrieve_company_wise&token=${token}&comp_id=${id}&type=${type}`
  );
};

export const getCompanyAPI = (id) => {
  return axios.get(
    `${URL}company.php?user_id=${userid}&method=retrieveid&token=${token}&id=${id}&type=${type}`
  );
};

export const getManagerWiseCompanyList = (id) => {
  return axios.get(
    `${URL}company.php?user_id=${userid}&method=retrieve&token=${token}&type=Manager&parent_id=${id}`
  );
};

export const getRouteWiseCountryList = (routeName) => {
  return axios.get(
    `${URL}users-as.php?user_id=${userid}&method=get_country_new&token=${token}&type=${type}&route_name=${routeName}`
  );
};

export const getPriceList = (id) => {
  return axios.get(
    `${URL}route_pricelist.php?user_id=${userid}&method=retrieve&route_id=${id}&token=${token}&type=${type}`
  );
};

export const getRouteList = () => {
  return axios.get(
    `${URL}smsc_ec2.php?user_id=${userid}&method=retrieve&token=${token}&type=${type}`
  );
};

export const addrouteAndRateAPI = (data) => {
  return axios.post(`${URL}user_pricelist.php`, data);
};

export const getRouteAndRateList = (system_id) => {
  return axios.get(
    `${URL}user_pricelist.php?user_id=${userid}&method=retrieve&system_id=${system_id}&token=${token}&type=${type}`
  );
};

export const routeAPI = (method, id, price) => {
  if (method === "delete") {
    return axios.get(
      `${URL}route.php?user_id=${userid}&method=delete_single&id=${id}&token=${token}&type=${type}`
    );
  }
  if (method === "delete_single") {
    return axios.get(
      `${URL}route_pricelist.php?user_id=${userid}&method=delete_single&id=${id}&token=${token}&type=${type}`
    );
  }
  if (method === "retrieveid") {
    return axios.get(
      `${URL}route.php?user_id=${userid}&method=retrieveid&id=${id}&token=${token}&type=${type}`
    );
  }
  if (method === "update") {
    return axios.get(
      `${URL}route_pricelist.php?user_id=${userid}&method=update&id=${id}&buy_price=${price}&token=${token}&type=${type}`
    );
  }
};

export const retrieveRouteAndRateList = (id, system_id) => {
  return axios.get(
    `${URL}user_pricelist.php?user_id=${userid}&method=retrieve_id&system_id=${system_id}&id=${id}&token=${token}&type=${type}`
  );
};

export const retrieveUserAssignedRoute = () => {
  return axios.get(
    `${URL}users-as.php?user_id=${userid}&method=user_routelist&token=${token}&type=${type}`
  );
};

export const getAllVendorList = () => {
  return axios.get(
    `${URL}company_vendor.php?user_id=${userid}&method=retrieve&token=${token}&type=${type}`
  );
};

export const addVendor = (data) => {
  return axios.post(`${URL}company_vendor.php`, data);
};

export const getVendorDetails = (id) => {
  return axios.post(
    `${URL}company_vendor.php?user_id=${userid}&method=retrieveid&id=${id}&token=${token}&type=${type}`
  );
};

export const addRoute = (method, data, retrieve_vendorID) => {
  if (method === "create") {
    return axios.post(`${URL}route.php`, data);
  }
  if (method === "update") {
    return axios.post(`${URL}route.php`, data);
  }
  if (method === "retrieve") {
    return axios.post(
      `${URL}route.php?user_id=${userid}&method=retrieve&token=${token}&type=${type}`
    );
  }
  if (method === "retrieveid") {
    return axios.post(
      `${URL}route.php?user_id=${userid}&method=retrieveid&id=${retrieve_vendorID}&token=${token}&type=${type}`
    );
  }
  if (method === "retrieve_vendor") {
    return axios.post(
      `${URL}route.php?user_id=${userid}&method=retrieve_vendor&comp_id=${retrieve_vendorID}&token=${token}&type=${type}`
    );
  }
};

export const getAllCountryList = (id) => {
  return axios.get(
    `${URL}users-as.php?user_id=${userid}&method=get_country_list&token=${token}&type=${type}&route_id=${id}`
  );
};

export const getMasterCountryList = () => {
  return axios.post(
    `${URL}user_pricelist.php?user_id=${userid}&method=country_list&token=${token}&type=${type}`
  );
};

export const deleteRateList = (id, system_id) => {
  return axios.post(
    `${URL}user_pricelist.php?user_id=${userid}&method=delete_single&id=${id}&system_id=${system_id}&token=${token}&type=${type}`
  );
};

export const bililingAPi = (method, dateFrom, DateTo, id) => {
  if (method === 'cus_summary_all') {
    return axios.get(`${URL}billing.php?user_id=${userid}&method=cus_summary_all&date_from=${dateFrom}&date_to=${DateTo}&token=${token}&user_type=${type}`)
  }
  if (method === 'cus_summary_companywise') {
    return axios.get(`${URL}billing.php?user_id=${userid}&method=cus_summary_companywise&comp_id=${id}&date_from=${dateFrom}&date_to=${DateTo}&token=${token}&user_type=${type}`)
  }
}


export const vendorbililingAPi = (method, dateFrom, DateTo, id) => {
  if (method === 'ven_summary_all') {
    return axios.get(`${URL}billing.php?user_id=${userid}&method=ven_summary_all&date_from=${dateFrom}&date_to=${DateTo}&token=${token}&user_type=${type}`)
  }
  if (method === 'ven_summary_companywise') {
    return axios.get(`${URL}billing.php?user_id=${userid}&method=ven_summary_companywise&comp_id=${id}&date_from=${dateFrom}&date_to=${DateTo}&token=${token}&user_type=${type}`)
  }
}

export const getUserDetailsAPI =()=> {
  return axios.get(`${URL}users.php?user_id=${userid}&method=retrieve&token=${token}&user_type=${type}`)
}

