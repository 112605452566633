import React, { useEffect, useState } from "react";
import { routeAPI } from "../../../../api/api";

function ModifyPriceList(props) {
  console.log('props', props.priceSet)
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState(false);
  const [newPrice, setNewPrice] = useState('');
    
  const [error, seterror] = useState({
    errarStatus: false,
    errorMessage: "",
    bgcolor: "",
  });

  console.log(props.priceSet)

  const addrouteAndRate = () => {
    setIsLoading(true);
    routeAPI("update", props.priceSet.id, newPrice).then((res) => {
        if (res.data.success === true) {
          seterror({
            errarStatus: true,
            errorMessage: res.data.message,
            bgcolor: "alert alert-success",
          });
          setTimeout(() => {
            window.location.reload(true);
          }, 1000);
        } else {
          seterror({
            errarStatus: true,
            errorMessage: res.data.message,
            bgcolor: "alert alert-danger",
          });
          setIsLoading(false);
        }
      });
  };

  useEffect(()=>{
    if(props){
      setNewPrice(props.priceSet.oldPrice)
    }
  },[props])


  return (
    <div
      className="modal fade"
      id="modifyPrice"
      tabIndex="-1"
      role="dialog"
      aria-labelledby="exampleModalbulkupload"
      aria-hidden="true"
    >
    {props.priceSet && 
      <div className="modal-dialog modal-dialog-centered" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="exampleModalLong">
             Update Price List <span className="text-success">
                {props.priceSet.route_name}
              </span>
            </h5>
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body row">
            <div className="col-md-12">
              {error.errarStatus ? (
                <div className={error.bgcolor} role="alert">
                  <strong>Alert!</strong> {error.errorMessage}
                  <button
                    type="button"
                    className="close"
                    data-dismiss="alert"
                    aria-label="Close"
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
              ) : null}
            </div>

            <div className="form-group col-md-12">
              <h6>Country Name : {props.priceSet.name} ({props.priceSet.country_code})</h6>
            </div>

            <div className="form-group col-md-12">
              <label>Buying Price (Euro)</label>
              <input
                placeholder="Enter new price"
                className="form-control"
                defaultValue={newPrice}
                type="text"
                onChange={(e)=> setNewPrice(e.target.value)}
              />
            </div>
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-secondary"
              data-dismiss="modal"
            >
              Close
            </button>
            {isLoading ? (
              <button type="button" className="btn btn-primary">
                Uploading.....
              </button>
            ) : (
              <button
                onClick={addrouteAndRate}
                type="button"
                className="btn btn-primary"
              >
                Update Price
              </button>
            )}
          </div>
        </div>
      </div>
}
    </div>
  );
}

export default ModifyPriceList;
