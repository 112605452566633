import React, { useState, useEffect } from "react";
import AdminSideMenu from "../../navbar/AdminSideMenu";
import AdminTopMenu from "../../navbar/AdminTopMenu";
import { addRoute, routeAPI } from "../../../api/api";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import AddRouteRatePopup from "../../common/AddRouteRatePopup";
import DeletePopup from "../../common/DeletePopup";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowLeft,
  faBackward,
  faEdit,
  faEye,
  faPlus,
  faTrashAlt,
} from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import BulkUploadRoute from "./components/BulkUploadRoute";
import PopupVendor from "./components/PopupVendor";
import AddPrice from "./components/AddPrice";

const AddedRouteList = () => {
  let { systemID,company_name } = useParams();

  let param = useParams()
  console.log(param)

  const [error, seterror] = useState({
    errarStatus: false,
    errorMessage: "",
    bgcolor: "",
  });
  const [bulkUploadPrice, setBulkUploadPrice] = useState({
    manager_id: "",
    verdor_name: "",
  });
  const [addPrice,setAddPrice] = useState({
    id: "",
    verdor_name: "",
  })
  const [vendorData, setVendorData] = useState({
    manager_id: "",
    parent_id: "",
    verdor_name: "",
  });
  const [routeAndrateList, setRouteAndrateList] = useState([]);
  const [updateID, setupdateID] = useState();

  const getUserDetails = () => {
    addRoute("retrieve_vendor", null, systemID).then((res) => {
      console.log("ddd", res.data);
      if (res.data.success === true) {
        setRouteAndrateList(res.data.data);
      } else {
        seterror({
          errarStatus: true,
          errorMessage: res.data.message,
          bgcolor: "alert alert-danger",
        });
      }
    });
  };

  const yesDelete = () => {
    routeAPI("delete", updateID).then((res) => {
      if (res.data.success === true) {
        seterror({
          errarStatus: true,
          errorMessage: res.data.message,
          bgcolor: "alert alert-success",
        });
        // setTimeout(() => {
        //   window.location.reload(true);
        // }, 1000);
      } else {
        seterror({
          errarStatus: true,
          errorMessage: res.data.message,
          bgcolor: "alert alert-danger",
        });
      }
    });
  };

  useEffect(() => {
    getUserDetails();
  }, []);

  return (
    <div className="layout-wrapper layout-content-navbar">
      <div className="layout-container">
        <AdminSideMenu systemID={systemID}/>

        <div className="layout-page">
          <AdminTopMenu />

          <div className="content-wrapper">
            <div className="container-xxl container-p-y">
              <div className="row">
                <div className="col-lg-12 col-md-4 order-1">
                  {error.errarStatus ? (
                    <div className={error.bgcolor} role="alert">
                      <strong>Alert!</strong> {error.errorMessage}
                      <button
                        type="button"
                        className="close"
                        data-dismiss="alert"
                        aria-label="Close"
                      >
                        <span aria-hidden="true">&times;</span>
                      </button>
                    </div>
                  ) : null}

                  <div className="">
                    <div className="card">
                      <h5 className="card-header ">
                        Route list{" "}
                        <span className="text-success">{systemID}</span>
                        <span className="demo-inline-spacing float-right ">
                          <Link
                            className="btn btn-primary me-1"
                            to="/admin/vendor"
                          >
                            <FontAwesomeIcon icon={faArrowLeft} /> Back to
                            vendor
                          </Link>
                        </span>
                      </h5>
                      <div
                        style={{ minHeight: "500px" }}
                        className="table-responsive text-nowrap"
                      >
                        <table className="table">
                          <thead>
                            <tr>
                              <th> title </th>
                              <th> username </th>
                              <th> vendor_username </th>
                              <th> ip_address </th>
                              <th>password</th>
                              <th>Status</th>
                              <th>Action</th>
                            </tr>
                          </thead>
                          <tbody className="table-border-bottom-0">
                            {routeAndrateList.map((items, i) => (
                              <tr key={i}>
                                <td>
                                  <span className="text-primary">
                                    {items.title}
                                  </span>
                                </td>

                                <td>
                                  <span className="text-primary">
                                    {items.username}
                                  </span>
                                </td>
                                <td>
                                  <span className="text-primary">
                                    {items.vendor_username}
                                  </span>
                                </td>

                                <td>{items.ip_address}</td>
                                <td>{items.password}</td>

                                <td>
                                  {items.status === 1 ? (
                                    <span className="badge bg-label-success me-1">
                                      Active
                                    </span>
                                  ) : (
                                    <span className="badge bg-label-danger me-1">
                                      Inactive
                                    </span>
                                  )}
                                </td>

                                <td>
                                  <div className="dropdown">
                                    <span
                                      type="button"
                                      id="dropdownMenuButton"
                                      data-toggle="dropdown"
                                      aria-haspopup="true"
                                      aria-expanded="false"
                                    >
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="16"
                                        height="16"
                                        fill="currentColor"
                                        className="bi bi-three-dots-vertical"
                                        viewBox="0 0 16 16"
                                      >
                                        <path d="M9.5 13a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0z" />
                                      </svg>
                                    </span>
                                    <div
                                      className="dropdown-menu"
                                      aria-labelledby="dropdownMenuButton"
                                    >
                                      <a
                                        className="dropdown-item"
                                        data-toggle="modal"
                                        data-target="#vendor"
                                        role="button"
                                        onClick={() =>
                                          setVendorData({
                                            manager_id: items.id,
                                            parent_id: items.parent_id,
                                            verdor_name: items.company_name,
                                          })
                                        }
                                      >
                                        <FontAwesomeIcon icon={faEdit} /> Edit
                                      </a>
                                      <a
                                        className="dropdown-item"
                                        data-target="#addPrice"
                                        data-toggle="modal"
                                        role="button"
                                        onClick={() =>
                                          setAddPrice({
                                            id: items.id,
                                            verdor_name: items.title,
                                          })
                                        }
                                      >
                                        <FontAwesomeIcon icon={faPlus} /> Add
                                        Price
                                      </a>
                                      <a
                                        className="dropdown-item"
                                        data-target="#bulkuploadRoute"
                                        data-toggle="modal"
                                        role="button"
                                        onClick={() =>
                                          setBulkUploadPrice({
                                            id: items.id,
                                            verdor_name: items.title,
                                          })
                                        }
                                      >
                                        <FontAwesomeIcon icon={faEye} /> Upload
                                        Price
                                      </a>
                                      <a
                                        href={`/admin/vendor/price-list/${items.id}/${items.title}`}
                                        className="dropdown-item"
                                      >
                                        <FontAwesomeIcon icon={faEye} /> View
                                        Price
                                      </a>
                                      <a
                                        className="dropdown-item"
                                        data-target="#delete"
                                        data-toggle="modal"
                                        onClick={(e) =>
                                          setupdateID(items.manager_id)
                                        }
                                      >
                                        <FontAwesomeIcon icon={faTrashAlt} />{" "}
                                        Delete
                                      </a>
                                    </div>
                                  </div>
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <BulkUploadRoute bulkUploadPrice={bulkUploadPrice} />
      <PopupVendor type={"edit"} id={updateID} vendorData={vendorData} />
      <DeletePopup yesDelete={yesDelete} />
      <AddPrice addPrice={addPrice} company_name={company_name}/>
    </div>
  );
};

export default AddedRouteList;
