import React, { useState, useEffect } from "react";
import AdminSideMenu from "../../navbar/AdminSideMenu";
import AdminTopMenu from "../../navbar/AdminTopMenu";
import { getPriceList, routeAPI } from "../../../api/api";
import {
  useHistory,
  useParams,
} from "react-router-dom/cjs/react-router-dom.min";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft, faEdit, faPlus, faRefresh, faTrash, faUpload } from "@fortawesome/free-solid-svg-icons";
import DeletePopup from "../../common/DeletePopup";
import ModifyPriceList from "./components/ModifyPriceList";
import BulkUploadRoute from "./components/BulkUploadRoute";
import AddPrice from "./components/AddPrice";

const PriceList = (props) => {
  let { id, name } = useParams();
  let history = useHistory();
  const [updateID, setupdateID] = useState();
  const [priceSet, setPriceSet] = useState();
  const [error, seterror] = useState({
    errorStatus: false,
    errorMessage: "",
    bgcolor: "",
  });
  const [order, setOrder] = useState("ASC")


  const [routeAndrateList, setRouteAndrateList] = useState([]);
  const [bulkUploadPrice, setBulkUploadPrice] = useState({
    manager_id: "",
    verdor_name: "",
  });
  const [addPrice,setAddPrice] = useState({
    id: "",
    verdor_name: "",
  })

  console.log(routeAndrateList)

  const getUserDetails = () => {
    console.log("getUserDetails");
    getPriceList(id).then((res) => {
      console.log("ddd", res.data);
      if (res.data.success === true) {
        console.log(res.data.success)
        setRouteAndrateList(res.data.data);
      } else {
        seterror({
          errorStatus: true,
          errorMessage: res.data.message,
          bgcolor: "alert alert-danger",
        });
      }
    });
  };




  const yesDelete = () => {
    routeAPI("delete_single", updateID).then((res) => {
      if (res.data.success === true) {
        seterror({
          errorStatus: true,
          errorMessage: res.data.message,
          bgcolor: "alert alert-success",
        });
        setTimeout(() => {
          window.location.reload(true);
        }, 1000);
      } else {
        seterror({
          errorStatus: true,
          errorMessage: res.data.message,
          bgcolor: "alert alert-danger",
        });
      }
    });
  };

  useEffect(() => {
    getUserDetails();
  }, []); 

  const sorting = (col) => {
    if (order === 'ASC') {
      const sorted = [...routeAndrateList].sort((a, b) =>
        a[col]?.toLowerCase() > b[col]?.toLowerCase() ? 1 : -1
      )
      setRouteAndrateList(sorted);
      setOrder("DSC")
    }
    if (order === 'DSC') {
      const sorted = [...routeAndrateList].sort((a, b) =>
        a[col]?.toLowerCase() < b[col]?.toLowerCase() ? 1 : -1
      )
      setRouteAndrateList(sorted);
      setOrder("ASC")
    }
  }


  return (
    <div className="layout-wrapper layout-content-navbar">
      <div className="layout-container">
        <AdminSideMenu id={id} name={name} />

        <div className="layout-page">
          <AdminTopMenu />

          <div className="content-wrapper">
            <div className="container-xxl container-p-y">
              <div className="row">
                <div className="col-lg-12 col-md-4 order-1">
                  {error.errorStatus ? (
                    <div className={error.bgcolor} role="alert">
                      <strong>Alert!</strong> {error.errorMessage}
                      <button
                        type="button"
                        className="close"
                        data-dismiss="alert"
                        aria-label="Close"
                      >
                        <span aria-hidden="true">&times;</span>
                      </button>
                    </div>
                  ) : null}

                  <div className="">
                    <div className="card">
                      <h5 className="card-header ">
                        Price list <span className="text-success">{name}</span>
                        <span className="demo-inline-spacing float-right">
                          <span 
                            onClick={() => history.goBack()}
                            className="btn btn-primary me-1"
                          >
                            <FontAwesomeIcon icon={faArrowLeft} /> Go Back
                          </span>
                          <span
                           data-target="#bulkuploadRoute"
                           data-toggle="modal"
                           role="button"
                            onClick={() =>
                              setBulkUploadPrice({
                                id: id,
                                verdor_name: name,
                              })
                            }
                            className="btn btn-primary me-1"
                          >
                             
                            <FontAwesomeIcon icon={faUpload} /> Upload File
                          </span>
                          <span
                          data-target="#addPrice"
                          data-toggle="modal"
                          role="button"
                          onClick={() =>
                            setAddPrice({
                              id: id,
                              verdor_name: name,
                            })
                          }
                            className="btn btn-primary me-1"
                          >
                             
                            <FontAwesomeIcon icon={faPlus} /> Add Price
                          </span>
                        </span>
                      </h5>
                      <div
                        style={{ minHeight: "500px" }}
                        className="table-responsive text-nowrap"
                      >
                        <table className="table">
                          <thead>
                            <tr>
                              <th  style={{cursor:"pointer"}} onClick={() => sorting("country_code")}> Country Name </th>
                              <th  style={{cursor:"pointer"}} onClick={() => sorting("buy_price")}> Buying Price </th>
                              <th > Route Name </th>
                              <th >Status</th>
                              <th style={{cursor:"pointer"}} onClick={() => sorting("created")}>created</th>
                              <th>Action</th>
                            </tr>
                          </thead>
                          <tbody className="table-border-bottom-0">
                            {routeAndrateList.map((items, i) => (
                              <tr key={i}>
                                <td>
                                  <span className="text-primary">
                                    {items.country_name}({items.country_code})
                                  </span>
                                </td>

                                <td>
                                  <span className="text-primary">
                                    {items.buy_price}
                                  </span>
                                </td>
                                <td>
                                  <span className="text-primary">
                                    {items.route_name}
                                  </span>
                                </td>

                                <td>
                                  {items.status === 1 ? (
                                    <span className="badge bg-label-success me-1">
                                      Active
                                    </span>
                                  ) : (
                                    <span className="badge bg-label-danger me-1">
                                      Inactive
                                    </span>
                                  )}
                                </td>
                                <td>{items.created}</td>
                                <td>
                                  <span
                                    role="button"
                                    className="badge bg-danger mr-2"
                                    data-target="#delete"
                                    data-toggle="modal"
                                    onClick={() => setupdateID(items.id)}
                                  >
                                    <FontAwesomeIcon icon={faTrash} /> Delete
                                  </span>
                                  <span
                                    role="button"
                                    className="badge bg-primary mr-2"
                                    data-target="#modifyPrice"
                                    data-toggle="modal"
                                    onClick={() => setPriceSet({
                                      id: items.id,
                                      name: items.country_name,
                                      oldPrice: items.buy_price,
                                      route_name: items.route_name,
                                      country_code: items.country_code
                                    })}
                                  >
                                    <FontAwesomeIcon icon={faEdit} /> Update Price
                                  </span>
                                </td>

                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <DeletePopup yesDelete={yesDelete} />
      {priceSet && <ModifyPriceList priceSet={priceSet} />}
      {/* {bulkUploadPrice && <BulkUploadRoute  />} */}
      {/* {openPopup && <BulkUploadRoute bulkUploadPrice={bulkUploadPrice} />} */}
      <BulkUploadRoute bulkUploadPrice={bulkUploadPrice} />
      <AddPrice addPrice={addPrice} company_name={name}/>
    </div>
  );
};

export default PriceList;
