import { ActionType } from "../contants/action-types"

const initialSMSC_Sate = {
    smsc: [],
}
export const smscReducer = (state = initialSMSC_Sate , { type, payload }) => {
    switch (type) {
        case ActionType.GET_SMSC_LIST:
            return {...state,smsc:payload};
        default:
            return state;
    }
}