import React, { useState } from 'react';

function Alert(props) {

    const [error, seterror] = useState({
        errarStatus: props.status,
        errorMessage: props.message,
        bgcolor: props.color
    });
    


    return (
        <>
        {
            error.errarStatus ?
                <div className={error.bgcolor} role="alert">
                    <strong>Alert!</strong> {error.errorMessage}
                    <button type="button" className="close" data-dismiss="alert" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                :
                null
        }
        </>
    );
}

export default Alert;