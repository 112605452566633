import React, { useState, useEffect } from "react";
import AdminSideMenu from "../../navbar/AdminSideMenu";
import AdminTopMenu from "../../navbar/AdminTopMenu";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { URL } from "../../common/Url";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import reactRouterDom, { Link } from "react-router-dom";
import useSelectedDate from "../../../store/UseFilterDateStore";
import Select from 'react-select'
import { Redirect } from "react-router-dom";
import { userid, token, type } from "../../common/AdminAuth";
import { getUserDetailsAPI } from "../../../api/api";

const Request = () => {

  var date = new Date();
  var todatdate =
    date.getFullYear() +
    "-" +
    (date.getMonth() + 1) +
    "-" +
    ("0" + date.getDate()).slice(-2);

  const setStoreDateFrom = useSelectedDate((state) => state.setStoreDateFrom);
  const setStoreDateTo = useSelectedDate((state) => state.setDateTo);

  const selctedDateFrom = useSelectedDate((state) => state.selctedDateFrom);
  const selctedDateTo = useSelectedDate((state) => state.selctedDateTo);

  const [DateFrom, setDateFrom] = useState(selctedDateFrom);
  const [DateTo, setDateTo] = useState(selctedDateTo);
  const [showDateFrom, setshowDateFrom] = useState(new Date());
  const [showDateTo, setshowDateTo] = useState(new Date());
  const [selectedUser, setselectedUser] = useState("");
  const [isloading, setisloading] = useState(false);
  const [userData, setUserData] = useState([]);
  const [redirect, setRedirect] = useState(false);
  const [validUser,setValidUser] = useState(false)
  const [validDate,setValidDate] = useState(false)


  useEffect(() => {
    getUserDetails();
  }, []);


  const handleDateFrom = (date) => {
    let dated =
      date.getFullYear() +
      "-" +
      (date.getMonth() + 1) +
      "-" +
      ("0" + date.getDate()).slice(-2);
    setDateFrom(dated);
    setStoreDateFrom(dated);
    setshowDateFrom(date);
  };

  const handleDateTo = (date) => {
    let dated =
      date.getFullYear() +
      "-" +
      (date.getMonth() + 1) +
      "-" +
      ("0" + date.getDate()).slice(-2);
    setDateTo(dated);
    setStoreDateTo(dated);
    setshowDateTo(date);
  };


  const getUserDetails = () => {
    getUserDetailsAPI().then((res) => {
      console.log(res.data)
      if (res.data.success) {
        setUserData(res.data.data)
      }
    })
  }

  const handleUserChange = (selectedOption) => {
    setselectedUser(selectedOption);
  };

  const isValid = () => {
    if (DateFrom > DateTo) {
      setValidDate(true)
      return false
    }
    
    if (!selectedUser.label){
     setValidUser(true)
      return false
    }
   return true
  }

  const listdata = async () => {
    if(isValid()){
    try {
      let result = await fetch(`https://smppintl.datagenit.com/apismpp/v1/download_centre.php?user_id=${userid}&method=create&token=${token}&user_type=${type}&startdate=${DateFrom}&enddate=${DateTo}&system_id=${selectedUser.label}`, {
        method: "post",
        headers: {
          "Content-type": "application/json; charset=UTF-8",
        },
        body: JSON.stringify({
        }),
      })
      if (result.ok) {
        console.log("Data Saved Successfully")
        console.log(result)
        setRedirect(true);

      }
    } catch (error) {
      console.log(error)
    }
  }
  }
  console.log(userData)


  if (redirect) {
    return <Redirect to="/admin/list" />;
  }

  console.log(selectedUser)

  return (
    <div className="layout-wrapper layout-content-navbar">
      <div className="layout-container">
        <AdminSideMenu />

        <div className="layout-page">
          <AdminTopMenu />

          <div className="content-wrapper">
            <div className="container-xxl container-p-y">
              <div className="row">
                <div className="col-xl-12">
                  <div className="card mb-4">
                    <h5 className="card-header">Filter Option</h5>

                    <div className="card-body">
                      <div className="row gy-3">
                        <div className="col-md-3 col-6">
                          <label className="">From Date</label>
                          <div className="input-group">
                            <button
                              className="btn btn-outline-primary d-none d-sm-block"
                              type="button"
                              id="button-addon2"
                            >
                              {" "}
                              From{" "}
                            </button>
                            <DatePicker
                              className="form-control btn-block"
                              dateFormat="yyyy/MM/dd"
                              selected={showDateFrom}
                              onChange={handleDateFrom}
                              placeholderText={todatdate}
                            />
                          </div>
                        </div>

                        <div className="col-md-3 col-6">
                          <label>To Date</label>
                          <div className="input-group">
                            <button
                              className="btn btn-outline-primary d-none d-sm-block"
                              type="button"
                              id="button-addon2"
                            >
                              {" "}
                              To
                            </button>
                            <DatePicker
                              className="form-control btn-block"
                              dateFormat="yyyy/MM/dd"
                              selected={showDateTo}
                              onChange={handleDateTo}
                            />
                          </div>
                          
                        </div>

                        <div className="col-md-6 col-6">
                          <label>Select User</label>
                          <div>
                            <Select
                              options={userData.map((user) => ({
                                label: user.system_id,
                                value: user.system_id,
                              }))}
                              name="user"
                              value={selectedUser}
                              onChange={handleUserChange}
                              placeholder="Select User"
                              isSearchable
                            />
                          </div>
                          
                        </div>

                        <div className="col-md-12">
                          <div className="text-right">
                            {isloading ? (
                              <button
                                className="btn btn-primary"
                                type="button"
                                disabled
                              >
                                <span
                                  className="spinner-border spinner-border-sm"
                                  role="status"
                                  aria-hidden="true"
                                ></span>{" "}
                                Loading...
                              </button>
                            ) : (
                              <button
                                onClick={listdata}
                                className="btn btn-primary me-1"
                              >
                                Submit
                              </button>
                            )}
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-md-12 col-12">
                          {validDate && (<p style={{color:"red" , marginLeft:"40vh", fontSize:"20px"}}>From Date should be less that To Date !</p>)}
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-md-12 col-12">
                          {validUser && (<p style={{color:"red", marginLeft:"50vh" , fontSize:"20px"}}>Please Select the User !</p>)}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Request;
