let cookie = document.cookie.split(";");
let getadminDetails = "admin" + "=";

for (let i = 0; i <= cookie.length; i++) {
  let c = cookie[i];
  // console.log("c", c);
  // if (c.indexOf(getadminDetails) == 0) {
  //   console.log(c);
  // }
  // console.log("no record");
}

let admin =
  JSON.parse(localStorage.getItem("admin")) ||
  JSON.parse(localStorage.getItem("manager"));
// console.log(admin);
let userid, token, name, type;

if (localStorage.getItem("admin") || localStorage.getItem("manager") != null) {
  userid = admin.user.userId;
  name = admin.user.name;
  token = admin.user.token;
  type = admin.user.type;
} else {
  //console.log('admin Null');
}

export const getCookie = (cookieName) => {
  let name = cookieName + "_";
  let date = document.cookie.split(";");
  for (let i = 0; i <= date.length; i++) {}
};

export { userid, token, name, type };
