import React, { useEffect, useState } from "react";
import { routeAPI } from "../../../../api/api";

function RouteDetailsPopup(props) {
  const [routeDetails, setRouteDetails] = useState([]);

  const [error, seterror] = useState({
    errarStatus: false,
    errorMessage: "",
    bgcolor: "",
  });

  useEffect(() => {
    if (props.id) {
      routeAPI("retrieveid", props.id).then((res) => {
        //console.log("res", res.data.data);
        setRouteDetails(res.data.data);
      });
    }
  }, [props.id]);

  // console.log("routeDetails", routeDetails);

  return (
    <div
      className="modal fade"
      id="roteDetailsPopup"
      tabIndex="-1"
      role="dialog"
      aria-labelledby="exampleModalCenterTitle"
      aria-hidden="true"
    >
      <div
        className="modal-dialog modal-lg modal-dialog-centered"
        role="document"
      >
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="exampleModalLongTitle">
              Route Details
            </h5>
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body row">
            <div className="col-md-12">
              {error.errarStatus ? (
                <div className={error.bgcolor} role="alert">
                  <strong>Alert!</strong> {error.errorMessage}
                  <button
                    type="button"
                    className="close"
                    data-dismiss="alert"
                    aria-label="Close"
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
              ) : null}
            </div>
            <div>
              <div className="table-responsive text-nowrap">
                <table className="table">
                  <thead>
                    <tr>
                      <th>Title</th>
                      <th>Username</th>
                      <th>vendor_username</th>
                      <th>ip_address</th>
                      <th>created</th>
                    </tr>
                  </thead>
                  <tbody className="table-border-bottom-0">
                    {routeDetails &&
                      routeDetails.map((item) => (
                        <tr>
                          <td>{item.title}</td>
                          <td>{item.username}</td>
                          <td>{item.vendor_username}</td>
                          <td>{item.ip_address}</td>
                          <td>{item.created}</td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-secondary"
              data-dismiss="modal"
            >
              Close
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default RouteDetailsPopup;
